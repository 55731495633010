import InputValidateIcon from '@components/InputValidationIcon'
import { LAND_TITLE_DEED_OPTIONS } from '@lib/constants'
import { useRentalInfoContext } from '@lib/context/RentalInfoContext'
import { RadioVariant } from '@property-scout/core-ui'
import { RadioGroup } from '@property-scout/core-ui/lib/Forms/RadioGroup'
import { InputActiveColor } from '@property-scout/core-ui/lib/Forms/utils'
import clsx from 'clsx'
import React, { FC, useMemo } from 'react'

interface ILandTitleDeedProps {
  valid?: boolean
}
const LandTitleDeed: FC<ILandTitleDeedProps> = ({ valid }) => {
  const { form: rental, setField } = useRentalInfoContext()
  const invalid = useMemo(() => (valid === undefined ? false : !valid), [valid])
  const labelClassName = clsx('mr-2 shrink-0', invalid && 'text-red-500')

  return (
    <div className="flex items-center flex-wrap gap-2">
      <div className={labelClassName}>
        Land Title Deed
        <InputValidateIcon valid={valid} />
      </div>
      <RadioGroup
        activeColor={InputActiveColor.orangeSolid}
        onChange={setField('landTitleDeed')}
        value={rental.landTitleDeed}
        className="inline-flex !mb-0 flex-wrap gap-y-2"
        itemClassName="!p-1 text-sm !mr-1 !mb-0"
        labelClassName="inline-flex mr-2"
        options={LAND_TITLE_DEED_OPTIONS}
        variant={RadioVariant.buttons}
      />
    </div>
  )
}

export default LandTitleDeed
