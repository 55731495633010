import { PrimaryButton as Button } from '@property-scout/core-ui/lib/Buttons/PrimaryButton'
import { INACTIVE_SLIDE } from '@property-scout/core-ui/lib/ImageFullView'
import React, { FC } from 'react'

interface ISliderActionSlideProps {
  disableSubmit: boolean
  setModal: (value: number) => void
  onClickSubmit: () => void
}

export const SliderActionSlide: FC<ISliderActionSlideProps> = ({ disableSubmit, setModal, onClickSubmit }) => {
  return (
    <div className="flex justify-center items-center mt-[35vh]">
      <div className="flex gap-2">
        <Button className="bg-red-600" onClick={(): void => setModal(INACTIVE_SLIDE)}>
          Issue Found
        </Button>
        <Button className="!bg-green-600" disabled={disableSubmit} onClick={onClickSubmit}>
          All Ok
        </Button>
      </div>
    </div>
  )
}
