import InputValidateIcon from '@components/InputValidationIcon'
import { PROPERTY_TYPE_OPTIONS } from '@lib/constants'
import { useRentalInfoContext } from '@lib/context/RentalInfoContext'
import { RadioGroup, RadioVariant } from '@property-scout/core-ui/lib/Forms/RadioGroup'
import { InputActiveColor } from '@property-scout/core-ui/lib/Forms/utils'
import clsx from 'clsx'
import React, { FC, useMemo } from 'react'

interface IPropertyTypeProps {
  valid?: boolean
}
const PropertyType: FC<IPropertyTypeProps> = ({ valid }) => {
  const { form: rental, setField } = useRentalInfoContext()
  const invalid = useMemo(() => (valid === undefined ? false : !valid), [valid])
  const labelClassName = clsx('mr-2 leading-loose grow shrink-0 basic-auto', invalid && 'text-red-500')

  return (
    <div className="flex items-start">
      <div className={labelClassName} data-testid="propertyType-label">
        Property Type
        <InputValidateIcon valid={valid} />
      </div>
      <RadioGroup
        activeColor={InputActiveColor.orangeSolid}
        onChange={setField('propertyType')}
        value={rental.propertyType ?? ''}
        className="flex flex-wrap !mb-0 gap-y-2"
        itemClassName="!p-1 !px-2 !mr-0 !mb-0"
        labelClassName="inline-flex mr-2"
        options={PROPERTY_TYPE_OPTIONS}
        variant={RadioVariant.buttons}
      />
    </div>
  )
}

export default PropertyType
