import { axiosWithAuth } from '@api/instance'
import { GetBuildingResponse } from '@api/types'
import { HTPoiBuilding } from '@interfaces/building'
import { I18nObject } from '@interfaces/common'
import { getPoiBuildingFromId } from '@lib/api'
import { useRentalInfoContext } from '@lib/context/RentalInfoContext'
import { IVirtualComboboxItem } from '@property-scout/core-ui'
import { BubbleType } from '@property-scout/core-ui/lib/Forms/Bubbles'
import { IBubbleItem } from '@property-scout/core-ui/lib/Forms/Bubbles'
import { RemoteVirtualComboBox } from '@property-scout/core-ui/lib/Forms/RemoteVirtualComboBox'
import React, { useState } from 'react'
interface IProjectNameComboBoxItem extends IVirtualComboboxItem {
  lat?: number
  lng?: number
  name?: I18nObject
  originalItem?: HTPoiBuilding
}

type Props = {
  setBubbles: React.Dispatch<React.SetStateAction<IBubbleItem[]>>
}

const BuildingName: React.FC<Props> = ({ setBubbles }) => {
  const { form: rental, setForm } = useRentalInfoContext()
  const [autocompleteBuildingNameLoading, setAutocompleteBuildingNameLoading] = useState(false)

  const getAutoCompleteBuildingName = async (
    keyword: string,
    callback: (newOptions: IProjectNameComboBoxItem[]) => void,
  ): Promise<void> => {
    setAutocompleteBuildingNameLoading(true)
    try {
      const { data } = await axiosWithAuth<GetBuildingResponse>({
        method: 'GET',
        url: `/getBuildingNames`,
        params: { keyword },
      })
      const parsedItems = data.data.map((item) => ({
        name: item.name,
        content: `${item.name.en} (${item.name.th})`,
        id: item.id,
        lat: item.lat,
        lng: item.lng,
        originalItem: item,
        name_original: item.name,
      }))

      callback(parsedItems)
    } catch (e) {
      console.error('Error during loading building name options', e)
    }
    setAutocompleteBuildingNameLoading(false)
  }

  const checkValidBuilding = async (poibuilding?: HTPoiBuilding): Promise<void> => {
    try {
      if (!poibuilding) {
        setForm((current) => {
          return {
            ...current,
            poiBuilding: null,
            poiBuildingObj: null,
            buildingText: '',
            buildingName: null,
            rawScrape: current?.rawScrape.filter((r) => r.key !== 'buildingName') || [],
            lat: '',
            lng: '',
          }
        })

        return
      }

      const { data } = await getPoiBuildingFromId(poibuilding.id)

      setForm((current) => ({
        ...current,
        poiBuilding: poibuilding.id.toString(),
        poiBuildingObj: data,
        buildingText: `${data.name.en} (${data.name.th})`,
        buildingName: data.name.en,
        place: null,
        ...(poibuilding && {
          lat: poibuilding.lat,
          lng: poibuilding.lng,
        }),
      }))
    } catch (error) {
      const bubblesData = {
        type: BubbleType.error,
        text: `Building not available for usage now`,
      } as IBubbleItem
      setBubbles((bubbles) => [...bubbles, bubblesData])

      return
    }
  }

  return (
    <RemoteVirtualComboBox
      className="w-full"
      label="Building"
      placeholder="find matching building"
      loading={autocompleteBuildingNameLoading}
      fetch={getAutoCompleteBuildingName}
      onChange={(value?: IProjectNameComboBoxItem): void => {
        checkValidBuilding((value || {}).originalItem).finally()
      }}
      value={
        rental.buildingText && !['', ' '].includes(rental.buildingText) ? { content: rental.buildingText } : undefined
      }
      disableCustomInput
      filterPredicate={() => true}
    />
  )
}

export default BuildingName
