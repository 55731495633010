import { getLogger, LoggerDetails } from '@property-scout/common-logger'
import { useCallback, useState } from 'react'
const isEvent = (e: any) => e && !!e.target
const logger = getLogger({ filename: __filename, category: 'useForm-hooks' })

export const getValue = (e: any) => {
  if (!isEvent(e)) {
    return e
  }
  switch (e.target.type) {
    default:
    case 'text':
    case 'number':
      return e.target.value
    case 'checkbox':
      return e.target.checked
  }
}
export interface FormHook<T, k> {
  form: T
  setForm: React.Dispatch<React.SetStateAction<T>>
  setField: (e: any) => (e: any) => void
  setError: React.Dispatch<React.SetStateAction<k>>
  error: k
  validateForm: () => boolean
  isFormError: (formError: Record<string, string>) => boolean
  overrideForm: (data: T) => void
}

export interface useFormOption<T, k> {
  formValidation?: (form: T) => k
}

const useForm = <T, k>(initFormState: T = {} as T, option: useFormOption<T, k> = {}): FormHook<T, k> => {
  const [error, setError] = useState<k>({} as k)
  const [form, setForm] = useState<T>(initFormState)
  const setField = useCallback(
    (field) => (e: any) => {
      const value = getValue(e)
      setForm((currentForm) => ({
        ...currentForm,
        [field]: value,
      }))
    },
    [setForm],
  )
  const isFormError = useCallback((formError) => Object.keys(formError).some((key) => formError[key]), [])

  const validateForm = useCallback(() => {
    logger.info('option.formValidation', option.formValidation as unknown as LoggerDetails)
    if (!option.formValidation) {
      return true
    }
    const formError = option.formValidation(form)
    logger.error('validateForm.formError', formError as LoggerDetails)
    setError(formError)

    return !isFormError(formError)
  }, [form, option, isFormError])

  const overrideForm = useCallback((data) => setForm((current) => ({ ...current, data })), [setForm])

  return {
    form,
    setForm,
    setField,
    setError,
    error,
    validateForm,
    isFormError,
    overrideForm,
  }
}

export default useForm
