import InputValidateIcon from '@components/InputValidationIcon'
import { TENURE_OPTIONS } from '@lib/constants'
import { useRentalInfoContext } from '@lib/context/RentalInfoContext'
import { RadioGroup, RadioVariant } from '@property-scout/core-ui/lib/Forms/RadioGroup'
import { InputActiveColor } from '@property-scout/core-ui/lib/Forms/utils'
import clsx from 'clsx'
import React, { FC, useMemo } from 'react'

interface IRentOrSellProps {
  valid?: boolean
}

const RentOrSell: FC<IRentOrSellProps> = ({ valid }) => {
  const { form: rental, setField } = useRentalInfoContext()
  const invalid = useMemo(() => (valid === undefined ? false : !valid), [valid])

  const labelClassName = clsx('font-semibold mr-2 shrink-0', invalid && 'text-red-500')

  return (
    <div className="flex items-center">
      <div className={labelClassName} data-testid="tenure-label">
        Rent/Sell
        <InputValidateIcon valid={valid} />
      </div>
      <RadioGroup
        activeColor={InputActiveColor.orangeSolid}
        onChange={setField('tenure')}
        value={rental.tenure ?? ''}
        className="inline-flex !mb-0 flex-wrap gap-y-2"
        itemClassName="!p-1 !px-2 !mr-1 !mb-0"
        labelClassName="inline-flex mr-2"
        options={TENURE_OPTIONS}
        variant={RadioVariant.buttons}
      />
    </div>
  )
}

export default RentOrSell
