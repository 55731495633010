import './login.css'

import * as G from '@lib/constants'
import { Spin } from '@property-scout/core-ui'
import { PrimaryButton as Button } from '@property-scout/core-ui/lib/Buttons/PrimaryButton'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { handleSubmitLoginForm, verifyJWT } from './login.helper'

export const Login: FC = () => {
  const [userName, setUserName] = useState('')
  const [password, setPassword] = useState('')
  const [message, setErrorMessage] = useState('')
  const navigate = useNavigate()
  const [isLogin, setIsLogin] = useState(false)
  const [loading, setShowLoading] = useState(true)
  const [waitingVerifyJWT, setWaitingVerifyJWT] = useState(false)
  const { pathname: path } = useLocation()
  const isDefaultPath = path === `/login` || path === G.BASE_PATH

  useEffect(() => {
    if (isLogin && isDefaultPath) {
      return navigate(`/rental-list/`)
    } else if (isLogin && !isDefaultPath) {
      return navigate(path)
    }
  }, [isLogin, navigate, path, isDefaultPath])

  const RentalListTableVerifyJWT = async () => {
    const confirmed = await verifyJWT()
    setWaitingVerifyJWT(false)
    setIsLogin(confirmed)
  }

  useEffect(() => {
    const jwt = localStorage.getItem(G.TOKEN_KEY)
    if (jwt) {
      setWaitingVerifyJWT(true)
      RentalListTableVerifyJWT()
    }
  }, [])

  useEffect(() => {
    // add this code for loading verify jwt
    const timer = setTimeout(() => setShowLoading(false), 400)

    return () => clearTimeout(timer)
  }, [])

  const handleChangeUserName = useCallback((e) => {
    const value = e.target.value.trim()
    if (value) {
      setUserName(value)
    }
  }, [])

  const handleChangePassword = useCallback((e) => {
    const value = e.target.value.trim()
    if (value) {
      setPassword(value)
    }
  }, [])

  const handleSubmit = useCallback(async () => {
    setShowLoading(true)
    const response = await handleSubmitLoginForm(userName, password)
    if (response?.confirmed) {
      return navigate(`/rental-list/`)
    }
    setShowLoading(false)
    setErrorMessage(response?.message ?? '')
  }, [userName, password, navigate])

  return (
    <div className="login-form">
      {loading || waitingVerifyJWT ? (
        <Spin className="w-9 h-9" />
      ) : (
        <div>
          <form method="post" onSubmit={handleSubmit}>
            <div>
              <span className="form-error">{message}</span>
            </div>
            <div className="form-control-group">
              <label className="form-label" htmlFor="identifier">
                Username or E-mail
              </label>
              <input
                onChange={handleChangeUserName}
                className="form-input"
                data-testid="username"
                required
                placeholder="User name or email"
                type="text"
              />
            </div>
            <div className="form-control-group">
              <label className="form-label" htmlFor="password">
                Password
              </label>
              <input
                onChange={handleChangePassword}
                className="form-input"
                data-testid="password"
                required
                placeholder="Password"
                type="password"
              />
            </div>
            <Button className="form-submit-btn" onClick={handleSubmit}>
              Log In
            </Button>
          </form>
        </div>
      )}
    </div>
  )
}

export default Login
