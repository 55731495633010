export interface UserInfo {
  username?: string
  [key: string]: any
}

let userInfo: UserInfo = {}

export const setUserInfo = (info?: UserInfo) => {
  userInfo = info ? { ...info } : {}
}

export const getUserInfo = (): UserInfo => ({ ...userInfo })
