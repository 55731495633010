import InputValidateIcon from '@components/InputValidationIcon'
import { IdContentPair } from '@interfaces/common'
import { SIZE_UNIT_OPTIONS } from '@lib/constants'
import { useRentalInfoContext } from '@lib/context/RentalInfoContext'
import { useStyles } from '@lib/hooks'
import { InputField as Input } from '@property-scout/core-ui/lib/Forms/InputField'
import { VirtualComboBox as Select } from '@property-scout/core-ui/lib/Forms/VirtualComboBox'
import clsx from 'clsx'
import trim from 'lodash/trim'
import React, { FC, useCallback, useMemo } from 'react'

interface ILandAreaProps {
  valid?: boolean
}

const LandArea: FC<ILandAreaProps> = ({ valid }) => {
  const { form: rental, setField } = useRentalInfoContext()
  const invalid = useMemo(() => (valid === undefined ? false : !valid), [valid])
  const styles = useStyles()
  const handleOnChange = useCallback(
    (value) => {
      if (value.charAt(0) === '.') {
        return
      }

      setField('landArea')(
        trim(value)
          .replace(/[^0-9.]/g, '')
          .replace(/(\..*)\./g, '$1'),
      )
    },
    [setField],
  )

  const handleUnitChange = useCallback(
    (value?: IdContentPair) => {
      setField('landAreaUnit')(value?.id)
    },
    [setField],
  )

  const selectedUnitValue = useMemo(() => {
    if (rental.landAreaUnit) {
      return SIZE_UNIT_OPTIONS.find((item) => item.id === rental.landAreaUnit)
    }

    return SIZE_UNIT_OPTIONS[0]
  }, [rental.landAreaUnit])

  const inputClassNames = clsx(styles.inputStyle(!invalid))

  return (
    <div className="flex items-center gap-2">
      <div className={clsx('shrink-0', invalid && 'text-red-500')} data-testid="landArea-label">
        Land Area
        <InputValidateIcon valid={valid} />
      </div>
      <div className="flex gap-2 w-[325px]">
        <Input
          className="!mb-0 flex-1"
          inputClassName={inputClassNames}
          value={rental.landArea!}
          onChange={handleOnChange}
          placeholder="Land Area"
        />
        <Select
          className="!mb-0 flex-1"
          value={selectedUnitValue}
          onChange={handleUnitChange}
          defaultValue={SIZE_UNIT_OPTIONS[0]}
          placeholder={rental.listingSource === 'facebook' ? 'Please choose unit' : ''}
          options={SIZE_UNIT_OPTIONS}
        />
      </div>
    </div>
  )
}

export default LandArea
