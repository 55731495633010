import { Dialog, PrimaryButton } from '@property-scout/core-ui'
import React, { FC } from 'react'

interface SessionExpireProps {
  isOpen: boolean
  onSessionExpire: () => void
}

export const SessionExpire: FC<SessionExpireProps> = ({ isOpen, onSessionExpire }) => {
  return (
    <Dialog
      title="Session Expired"
      isOpen={isOpen}
      onClose={() => {}}
      titleClassName="text-lg"
      containerClassName="pb-2"
      hasCloseBtn={false}
    >
      <p className="">Your session has expired. Please login again.</p>
      <div className="flex items-center mt-8 justify-center">
        <PrimaryButton onClick={onSessionExpire} className="nm:m-0">
          Rejoin
        </PrimaryButton>
      </div>
    </Dialog>
  )
}
