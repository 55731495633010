import { IdContentPair } from '@interfaces/common'
import { IColumn } from '@property-scout/core-ui/lib/RentalTable'
import { LinkCell } from '@property-scout/core-ui/lib/RentalTable/LinkCell'
import { PropertyTypeCell } from '@property-scout/core-ui/lib/RentalTable/PropertyTypeCell'
import unitTypes from '@property-scout/meta/lib/all-unit-types.json'
import availability from '@property-scout/meta/lib/availability.json'
import availabilityRentsell from '@property-scout/meta/lib/availability-rentsell.json'
import landTitleDeed from '@property-scout/meta/lib/land-title-deed.json'
import numberOfBathrooms from '@property-scout/meta/lib/number-of-bathrooms.json'
import popularAmenities from '@property-scout/meta/lib/popular-amenities.json'
import postBy from '@property-scout/meta/lib/post-by.json'
import propertyTypes from '@property-scout/meta/lib/property-types.json'
import propertyView from '@property-scout/meta/lib/property-view.json'
import sizeUnits from '@property-scout/meta/lib/size-unit.json'
import tenures from '@property-scout/meta/lib/tenures.json'

export const TOKEN_KEY = 'accessToken'
export const BUILDINGS = 'buildings'

export const SESSION_EXPIRE_TIMEOUT = process.env.SESSION_EXPIRE_TIMEOUT || 1_200_000 // -> 20min
export const AI_IMAGE_CHECK = process.env.REACT_APP_AI_IMAGE_CHECK === 'true'
export const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY
export const BASE_PATH = process.env.REACT_APP_PUBLIC_URL || ''
export const CDN_URL = process.env.REACT_APP_CDN_IMAGE_HOST
export const EMAIL_USER_ID = 'user_u6E38z1p8wryTQpaJjQY8'
export const EMAIL_SERVICE_ID = 'infogmail'
export const EMAIL_TEMPLATE_ID = 'template_LmbMITvy'
export const SERVICE_NAME = 'ps-manually-checking'
export const MAX_FILES_UPLOAD_COUNTS = 15
export const PS_HOME_URL = 'http://www.propertyscout.co.th'
export const PAGE_LIMIT = 20
const MARKER_DEFAULT_LAT = 13.7355503911
const MARKER_DEFAULT_LNG = 100.556249442
export const MARKER_DEFAULT = {
  lat: MARKER_DEFAULT_LAT,
  lng: MARKER_DEFAULT_LNG,
}

export const BUILDING_FEATURES = [
  { label: 'Pet Friendly', value: 'petsAllowed' },
  { label: 'Luxury', value: 'luxury' },
  { label: 'Oceanfront', value: 'oceanfront' },
  { label: 'Beachfront', value: 'beachfront' },
  { label: 'Resort Property', value: 'resortProperty' },
  { label: 'Golf Property', value: 'golfProperty' },
  { label: 'Near Beach', value: 'nearBeach' },
  { label: 'Communal Swimming Pool', value: 'communalSwimmingPool' },
  { label: 'Private Swimming Pool', value: 'amenitySwimmingPool' },
  { label: 'Kids Swimming Pool', value: 'communalKidsSwimmingPool' },
  { label: 'Gym', value: 'communalGym' },
  { label: 'Playground', value: 'communalKidsPlayground' },
  { label: 'Garden', value: 'communalGarden' },
  { label: 'Laundry Room', value: 'communalLaundryRoom' },
  { label: 'Roof-Top Terrace', value: 'communalRooftopTerrace' },
  { label: 'BBQ facilities', value: 'communalBbqGrill' },
  { label: 'Library', value: 'communalLibrary' },
  { label: 'Covered Parking', value: 'communalCoveredCarPark' },
  { label: 'Open Parking', value: 'communalOpenCarPark' },
  { label: 'Free Parking', value: 'communalFreeParking' },
  { label: 'Street Parking', value: 'communalStreetParking' },
  { label: 'Bike Parking', value: 'communalBikePark' },
  { label: '24hr Security', value: 'communalSecurity24Hrs' },
  { label: 'CCTV', value: 'communalCctv' },
  { label: 'Elevator', value: 'communalElevator' },
  { label: 'Shuttle Service', value: 'communalShuttleService' },
  { label: 'Function Room', value: 'communalFunctionalRoom' },
  { label: 'Convenience Store', value: 'communalConvenienceStore' },
  { label: 'Restaurant on Premise', value: 'communalRestaurantOnPremises' },
  { label: 'Shop on Premise', value: 'communalShopOnPremises' },
  { label: 'Tennis Court', value: 'communalTennisCourt' },
  { label: 'Badminton Court', value: 'communalBadmintonCourt' },
  { label: 'Basketball Court', value: 'communalBasketballCourt' },
  { label: 'Squash Court', value: 'communalSquashCourt' },
  { label: 'Jogging Track', value: 'communalJoggingTrack' },
  { label: 'Sauna', value: 'communalSauna' },
  { label: 'Steam Room', value: 'communalSteamroom' },
]

export const SIZE_UNIT_OPTIONS: IdContentPair[] = sizeUnits.map(({ name, mncLabel }) => ({
  id: name,
  content: mncLabel,
}))

export const FIRST_LINE_RELEVANCE_OPTIONS: IdContentPair[] = [
  {
    id: 'relevant',
    content: 'Relevant',
  },
]

export const SECOND_LINE_RELEVANCE_OPTIONS: IdContentPair[] = [
  {
    id: 'nopropertylisting',
    content: 'No property listing',
  },
  {
    id: 'no_agent',
    content: 'No agent',
  },
  {
    id: 'missing_details',
    content: 'Details miss.',
  },
  {
    id: 'other',
    content: 'Other not rel.',
  },
]

export const ACCEPTED_UPLOAD_IMAGE_FORMAT = {
  'image/jpeg': ['.jpeg', '.jpg'],
  'image/png': ['.png'],
  'image/webp': ['.webp'],
}

export const ACCEPTED_UPLOAD_IMAGE_SIZE = 2000000

export const BATHROOM_OPTIONS = numberOfBathrooms.map(({ name, mncLabel }) => ({
  id: name,
  content: mncLabel,
}))

export const AMENITIES = popularAmenities.map(({ name, mncLabel }) => ({
  label: name,
  value: mncLabel,
}))

export const LAND_TITLE_DEED_OPTIONS = landTitleDeed.map(({ name, mncLabel }) => ({
  id: name,
  content: mncLabel,
}))

export const POSTED_BY_OPTIONS = postBy.map(({ name, internalUsageLabel }) => ({
  id: name,
  content: internalUsageLabel,
}))

export const PROPERTY_TYPE_OPTIONS = propertyTypes.map(({ name, internalUsageLabel }) => {
  if (name === 'house') {
    return {
      id: name,
      content: 'Detached House',
    }
  }

  return {
    id: name,
    content: internalUsageLabel,
  }
})

export const TENURE_OPTIONS = tenures
  .map(({ name, internalUsageLabel }) => ({
    id: name,
    content: internalUsageLabel,
  }))
  .filter(({ id }) => id !== 'unknown')

export const PROPERTY_VIEW_OPTIONS = propertyView.map(({ name, mncLabel }) => ({
  id: name,
  content: mncLabel,
}))

export const RENTED_OUT_OPTIONS = availability.map(({ name, mncLabel }) => {
  if (name === 'unavailable') {
    return {
      id: 'rentedOut',
      content: mncLabel,
    }
  }

  return {
    id: name,
    content: mncLabel,
  }
})

const RENTED_OUT_RENTSELL = availabilityRentsell.map(({ name, mncLabel }) => ({
  id: name,
  content: mncLabel,
}))

const UNKNOWN_RENTSELL_OPTION = {
  id: 'unknown',
  content: 'No Information',
}

export const RENTED_OUT_RENTSELL_OPTIONS = [...RENTED_OUT_RENTSELL, UNKNOWN_RENTSELL_OPTION]

export const UNIT_TYPE_OPTIONS = unitTypes.map(({ name, mncLabel }) => ({
  id: name,
  content: mncLabel,
}))

export const columns: IColumn[] = [
  {
    key: 'id',
    title: 'ID',
    dataType: 'number' as IColumn['dataType'],
    style: { width: '8%', minWidth: 80 },
    isEditable: false,
  },
  {
    key: 'listingSource',
    title: 'Source',
    dataType: 'string' as IColumn['dataType'],
    style: { width: '8%', minWidth: 80 },
    isEditable: false,
  },
  {
    key: 'propertyType',
    title: 'Property Type',
    dataType: 'string' as IColumn['dataType'],
    style: { width: '12%', minWidth: 80 },
    CustomCell: PropertyTypeCell,
    isEditable: false,
  },
  {
    key: 'prioritized_area',
    title: 'Prioritized Area',
    dataType: 'boolean' as IColumn['dataType'],
    style: { width: '4%', textTransform: 'uppercase' },
    isEditable: false,
  },
  {
    key: 'extsourceCreatedAt',
    title: 'ExtsourceCreatedAt',
    dataType: 'date' as IColumn['dataType'],
    style: { width: '2%', minWidth: 120 },
    isEditable: false,
  },
  {
    key: 'extsourceUpdatedAt',
    title: 'ExtsourceUpdatedAt',
    dataType: 'date' as IColumn['dataType'],
    style: { width: '2%', minWidth: 120 },
    isEditable: false,
  },
  {
    key: 'tenure',
    title: 'Tenure',
    dataType: 'string' as IColumn['dataType'],
    style: { width: '4%' },
    isEditable: false,
  },
  {
    key: 'rating',
    title: 'Rating',
    dataType: 'number' as IColumn['dataType'],
    style: { width: '4%' },
    isEditable: false,
  },
  {
    key: 'bucket',
    title: 'Bucket',
    dataType: 'number' as IColumn['dataType'],
    style: { width: '4%' },
    isEditable: false,
  },
  {
    key: 'datasource',
    title: 'Data Source',
    dataType: 'string' as IColumn['dataType'],
    style: { width: '5%' },
    CustomCell: LinkCell,
    isEditable: false,
  },
]

export const ALLOWED_IMAGE_RESOLUTION = {
  width: 8000,
  height: 8000,
}
