import { BasicLoader } from '@property-scout/core-ui/lib/Loaders/BasicLoader'
import clsx from 'clsx'
import React, { FC } from 'react'

export const LoadingScreen: FC = () => {
  const numberOfRows = 20

  const renderedRows = [...Array(numberOfRows)].map(() => (
    <div className={clsx('grid grid-cols-7 gap-2 grid-rows-1 border-b border-gray-200 p-2')}>
      {new Array(7).fill(null).map((_, i) => (
        <BasicLoader className={clsx('nm:mb-1 mt-1')} key={i} />
      ))}
    </div>
  ))

  return (
    <div className={clsx('w-full  overflow-hidden mx-auto my-10 max-h-[90%]')}>
      <div className={clsx('grid grid-cols-7	gap-2 grid-rows-1	 border-b border-gray-200 p-2 bg-[#e5e7eb]')}>
        {new Array(7).fill(null).map((_, i) => (
          <BasicLoader className={clsx('nm:mb-1 mt-1')} key={i} />
        ))}
      </div>
      {renderedRows}
    </div>
  )
}
