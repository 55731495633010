import { axiosWithAuth } from '@api/instance'
import { GetListingSourcesResponse } from '@backend/types'
import { RemoteVirtualComboBox } from '@property-scout/core-ui/lib/Forms/RemoteVirtualComboBox'
import { InputActiveColor } from '@property-scout/core-ui/lib/Forms/utils'
import type { IVirtualComboboxItem } from '@property-scout/core-ui/lib/Forms/VirtualComboBox'
import React, { useCallback, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

export const ListingSourceFilter = (): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false)
  const [searchParams] = useSearchParams()
  const selectedListingSources =
    searchParams
      .get('listingSources')
      ?.split(',')
      .map((item) => decodeURIComponent(item))
      .map((item) => ({ id: item, content: item })) ?? []
  const navigate = useNavigate()

  const handleChange = useCallback(
    (fieldValue?: IVirtualComboboxItem[] | IVirtualComboboxItem | null): void => {
      const newParams = new URLSearchParams(searchParams)

      newParams.delete('page')
      if (fieldValue && Array.isArray(fieldValue) && fieldValue.length > 0) {
        const listingSources = fieldValue.map((item) => item.id).join(',')
        newParams.set('listingSources', listingSources)
      } else {
        newParams.delete('listingSources')
      }

      navigate(`/rental-list?${newParams.toString()}`, { replace: true })
    },
    [searchParams, navigate],
  )
  const onFetch = useCallback(
    async (inputValue: string, callback: (newOptions: IVirtualComboboxItem[]) => void): Promise<void> => {
      setLoading(true)

      try {
        const { data: response } = await axiosWithAuth<GetListingSourcesResponse>({
          method: 'GET',
          url: `/getListingSources/?keyword=${inputValue}`,
        })

        const parsedOptions = (response.data || []).map((source) => ({
          id: source,
          content: source,
        }))

        callback(parsedOptions)
      } catch (e) {
        console.error('Error during loading options', e)
      }

      setLoading(false)
    },
    [],
  )

  return (
    <RemoteVirtualComboBox
      placeholder="Listing Source"
      className="!mb-0 select-none w-[400px]"
      multiSelect
      loading={loading}
      hasIndicator={false}
      hasCheckbox
      onChange={handleChange}
      fetch={onFetch}
      activeColor={InputActiveColor.orange}
      name="listingSources"
      closeOnChoose={false}
      customValueIcon="search"
      value={selectedListingSources}
    />
  )
}
