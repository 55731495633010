import { TOKEN_KEY } from '@lib/constants'
import axios from 'axios'
import { AxiosRequestConfig } from 'axios'

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

export const axiosCMSInstance = axios.create({
  baseURL: process.env.REACT_APP_CMS_URL,
})

interface AxiosInstanceConfig extends AxiosRequestConfig {
  checkToken?: boolean
}

export const axiosCMSWithAuth = <T>(config: AxiosInstanceConfig) => {
  const token = window.localStorage.getItem(TOKEN_KEY)
  if (!token && config.checkToken) {
    throw new Error('Cannot find token')
  }

  const { headers, ...otherConfig } = config

  return axiosCMSInstance<T>({
    headers: {
      Authorization: `bearer ${token}`,
      'x-ps-service': 'ps-manually-checking',
      ...headers,
    },
    ...otherConfig,
  })
}

export const axiosWithAuth = <T>(config: AxiosInstanceConfig) => {
  const token = window.localStorage.getItem(TOKEN_KEY)
  if (!token && config.checkToken) {
    throw new Error('cannot find token')
  }
  const { headers, ...otherConfig } = config

  return axiosInstance<T>({
    headers: {
      Authorization: `bearer ${token}`,
      ...headers,
    },
    ...otherConfig,
  })
}

export const isTokenExpire = () => {
  const token = window.localStorage.getItem(TOKEN_KEY)
  if (!token) {
    throw new Error('cannot find token')
  }
  try {
    const decodedJwt = JSON.parse(Buffer.from(token.split('.')[1] ?? '', 'base64').toString())

    if (decodedJwt.exp * 1000 < Date.now()) {
      return true
    }
  } catch (e) {
    return false
  }
}
