import { PS_HOME_URL, TOKEN_KEY } from '@lib/constants'
import { getUserInfo, setUserInfo } from '@lib/userinfo'
import { PrimaryButton as Button } from '@property-scout/core-ui/lib/Buttons/PrimaryButton'
import { Svg } from '@property-scout/icons'
import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

export const Navbar: React.FC = () => {
  const navigate = useNavigate()

  const handleLogout = useCallback(() => {
    setUserInfo()
    localStorage.removeItem(TOKEN_KEY)
    navigate(`/login`)
  }, [navigate])

  const { username } = getUserInfo()

  return (
    <nav className="bg-white bg-white  sticky top-0 shadow-md">
      <div className=" flex flex-wrap items-center justify-between mx-auto py-4 px-6">
        <div className="flex items-center">
          <a href={PS_HOME_URL} target="_blank">
            <Svg src="logo-full" className="w-24" />
          </a>
          <h2 className="ml-8 font-bold text-[#16216d]">Manual Checking</h2>
          <span className="text-2xl text-gray-200 ml-4 mr-2"> | </span>
          <p className="font-semibold m-2 mt-3 ">Hello {username}</p>
        </div>
        <div className="flex md:order-2">
          <Button className="bg-red-600 !mb-0" onClick={handleLogout}>
            Log out
          </Button>
        </div>
      </div>
    </nav>
  )
}
