import { BASE_PATH, SESSION_EXPIRE_TIMEOUT } from '@lib/constants'
import { Login } from '@pages/Login'
import { RentalInfo } from '@pages/RentalInfo/RentalInfo'
import { RentalList } from '@pages/RentalList/RentalList'
import PrivateRoute from '@routes/PrivateRoute'
import React from 'react'
import { IdleTimerProvider } from 'react-idle-timer'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
const App: React.FC = () => {
  const queryClient = new QueryClient()

  return (
    <IdleTimerProvider timeout={+SESSION_EXPIRE_TIMEOUT} throttle={500} crossTab syncTimers={200}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Routes>
            <Route path={`${BASE_PATH}/`} element={<Login />} />
            <Route path={`${BASE_PATH}/login`} element={<Login />} />
            <Route path={`${BASE_PATH}/rental-list/`} element={<PrivateRoute component={RentalList} />} />
            <Route path={`${BASE_PATH}/rentals/:rentalId`} element={<PrivateRoute component={RentalInfo} />} />
          </Routes>
        </BrowserRouter>
      </QueryClientProvider>
    </IdleTimerProvider>
  )
}

export default App
