import { axiosCMSInstance as api, axiosCMSWithAuth, isTokenExpire } from '@api/instance'
import { IGetUserResponse } from '@api/types'
import * as G from '@lib/constants'
import { setUserInfo } from '@lib/userinfo'

export const verifyJWT = async () => {
  try {
    const url = `${process.env.REACT_APP_CMS_URL}/users/me`
    const { data, status } = await axiosCMSWithAuth<IGetUserResponse>({ method: 'GET', url })
    const { confirmed } = data

    if (isTokenExpire() || [401, 403].includes(status)) {
      return false
    }

    if (confirmed) {
      setUserInfo(data)
    }

    return confirmed
  } catch (error) {
    return false
  }
}

export const handleSubmitLoginForm = async (account: string, password: string) => {
  try {
    const body = { identifier: account, password }
    const { data } = await api.post(`${process.env.REACT_APP_CMS_URL}/auth/local`, body)
    const { jwt } = data

    if (jwt) {
      localStorage.setItem(G.TOKEN_KEY, jwt)
      const isValidJWT = await verifyJWT()

      return {
        confirmed: isValidJWT,
        messages: 'Account or password is wrong!',
      }
    }
  } catch (error) {
    return { confirmed: false, message: 'Account or password is wrong!' }
  }
}
