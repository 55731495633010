import InputValidateIcon from '@components/InputValidationIcon'
import { IdContentPair } from '@interfaces/common'
import { SIZE_UNIT_OPTIONS } from '@lib/constants'
import { useRentalInfoContext } from '@lib/context/RentalInfoContext'
import { useStyles } from '@lib/hooks'
import { InputField as Input } from '@property-scout/core-ui/lib/Forms/InputField'
import { VirtualComboBox as Select } from '@property-scout/core-ui/lib/Forms/VirtualComboBox'
import clsx from 'clsx'
import trim from 'lodash/trim'
import React, { FC, useCallback, useMemo } from 'react'

interface IFloorSizeProps {
  valid?: boolean
}

const FloorSize: FC<IFloorSizeProps> = ({ valid }) => {
  const { form: rental, setField } = useRentalInfoContext()
  const styles = useStyles()
  const invalid = useMemo(() => (valid === undefined ? false : !valid), [valid])
  const inputClassNames = clsx(styles.inputStyle(!invalid))

  const selectedUnitValue = useMemo(() => {
    if (rental.floorSize) {
      return SIZE_UNIT_OPTIONS.find((item) => item.id === rental.floorSize)
    }

    return SIZE_UNIT_OPTIONS[0]
  }, [rental.floorSize])

  const handleUnitChange = useCallback(
    (value?: IdContentPair) => {
      setField('floorSizeUnit')(value?.id)
    },
    [setField],
  )

  return (
    <div className="flex items-center gap-2">
      <div className={clsx(invalid && 'text-red-500')} data-testid="floorSize-label">
        Floor Size
        <InputValidateIcon valid={valid} />
      </div>
      <div className="flex gap-2 w-[325px]">
        <Input
          className="!mb-0 flex-1"
          inputClassName={inputClassNames}
          value={rental.floorSize ?? ''}
          testId="inputFloorSize"
          onChange={(value) => setField('floorSize')(trim(value as string).replace(/[^0-9.]/g, ''))}
          placeholder="Floor Size"
        />
        <Select
          className="!mb-0 flex-1"
          value={selectedUnitValue}
          onChange={handleUnitChange}
          defaultValue={SIZE_UNIT_OPTIONS[0]}
          placeholder={rental.listingSource === 'facebook' ? 'Please choose unit' : ''}
          options={SIZE_UNIT_OPTIONS}
        />
      </div>
    </div>
  )
}

export default FloorSize
