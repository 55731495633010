import { IUploadedImage } from '@interfaces/images'
import * as api from '@lib/api'
import { getLogger, LoggerDetails } from '@property-scout/common-logger'

const logger = getLogger({ filename: __filename, category: 'rental-image-upload' })

export const uploadImages = async (uploadedImages: IUploadedImage[]) => {
  try {
    const newImages: IUploadedImage[] = []
    await Promise.all(
      uploadedImages.map(async (image) => {
        const formData = new FormData()
        formData.append('files', image.file!)
        const { data } = await api.uploadRentalImages(formData)

        const { file, id, url, ...rest } = image
        logger.info('success image upload', data[0] as unknown as LoggerDetails)

        const newImage = {
          ...rest,
          url: data?.[0]?.Location,
        } as IUploadedImage

        newImages.push(newImage)

        return newImage
      }),
    )

    return newImages
  } catch (error) {
    logger.error('Image upload failed', error as LoggerDetails)

    throw new Error('Image upload failed')
  }
}
