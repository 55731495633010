import Badge from '@components/Badge'
import FormCard from '@components/FormCard'
import { useRentalInfoContext } from '@lib/context/RentalInfoContext'
import { getScrapByKey } from '@lib/modules/rental/form'
import { getNoRefUrl } from '@lib/url'
import React, { FC } from 'react'

const ListingInformation: FC = () => {
  const { form: rental } = useRentalInfoContext()

  return (
    <div className="p-2">
      <h2 className="font-bold mb-6">Infomation</h2>
      <FormCard>
        <h3 className="font-bold mb-6">Basic data</h3>
        <div className="my-2">
          <span className="font-bold">ID: </span>
          <Badge className="uppercase" colorScheme="green">
            {rental.id}
          </Badge>
        </div>

        <div className="my-2">
          <span className="font-bold">Url: </span>
          <span
            className="underline cursor-pointer overflow-auto break-words"
            role="presentation"
            onClick={() => window.open(getNoRefUrl(rental.datasource), '_blank', 'location=yes')}
          >
            {rental.datasource}
          </span>
        </div>
        <div className="my-2">
          <span className="font-bold">Listing source:</span>
          <Badge className="uppercase" colorScheme="green">
            {rental.listingSource}
          </Badge>
        </div>
      </FormCard>
      <FormCard className="sticky top-4">
        <h3 className="font-bold mb-6">Key information</h3>
        <div className="my-2">
          <span className="font-bold">Property type: </span>
          <Badge className="uppercase" colorScheme="green">
            {rental.propertyType}
          </Badge>
        </div>
        <div className="my-2">
          <div className="font-bold">TitleTh: </div>
          {getScrapByKey(rental.rawScrape, 'titleTh')}
        </div>
        <div className="my-2">
          <div className="font-bold">TitleEn: </div>
          {getScrapByKey(rental.rawScrape, 'titleEn')}
        </div>
        <div className="my-2">
          <div className="font-bold">rawDescTh: </div>
        </div>
        <div className="my-2 max-h-[250px] overflow-auto break-words">
          {getScrapByKey(rental.rawScrape, 'descriptionTh') || rental.descriptionThSource}
        </div>
        <div className="my-2">
          <div className="font-bold">rawDescEn: </div>
        </div>
        <div className="my-2 overflow-auto break-words">
          {getScrapByKey(rental.rawScrape, 'descriptionEn') || rental.descriptionEnSource}
        </div>
        <div className="my-2">
          <div className="font-bold">Building name: </div>
          {rental.buildingName}
        </div>

        <div className="my-2">
          <div className="font-bold">Name: </div>
          {getScrapByKey(rental.rawScrape, 'name')}
        </div>

        <div className="my-2">
          <div className="font-bold">Email: </div>
          {getScrapByKey(rental.rawScrape, 'email')}
        </div>
      </FormCard>
    </div>
  )
}

export default ListingInformation
