import { ISvgProps, Svg } from '@property-scout/icons'
import React, { FC } from 'react'

interface IInputValidateIconProps extends Omit<Partial<ISvgProps>, 'ref'> {
  valid?: boolean
}

const InputValidateIcon: FC<IInputValidateIconProps> = (props) => {
  const { valid, ...otherProps } = props
  if (valid === undefined) {
    return null
  }

  return (
    <div
      className={`text-white ${valid ? 'bg-green-600' : 'bg-red-600'}  inline-block ml-4 rounded-full w-5 h-5 p-[3px]`}
    >
      <Svg
        {...otherProps}
        src={valid ? 'check' : 'close'}
        data-testid={valid ? 'valid-icon' : 'invalid-icon'}
        className="invert w-full"
      />
    </div>
  )
}

export default InputValidateIcon
