export enum AbstractUnitType {
  one_bedroom = '1br',
  two_bedrooms = '2br',
  three_bedrooms = '3br',
  four_bedrooms = '4br',
  five_bedrooms = '5br',
  six_bedrooms = '6br',
  seven_bedrooms = '7br',
  eight_bedrooms = '8br',
  nine_bedrooms = '9br',
  ten_bedrooms = '10br',
  studio = 'studio',
}

export enum ExtraUnits {
  Sqwah = 'sqwah',
  Sqrai = 'sqrai',
}
