import { CheckingId } from '@api/types'
import { ListingSourceFilter } from '@components/ListingSourceFilter'
import { ProvinceFilter } from '@components/ProvinceFilter'
import { LoadingScreen } from '@components/RentalListTable/LoadingScreen'
import { RentalListTable } from '@components/RentalListTable/RentalListTable'
import * as api from '@lib/api'
import { useRentalList, useRentalListCount } from '@lib/api'
import socket from '@lib/socket'
import { getLogger, LoggerDetails } from '@property-scout/common-logger'
import toNumber from 'lodash/toNumber'
import moment from 'moment-timezone'
import React, { FC, useEffect, useMemo, useState } from 'react'

const logger = getLogger({ filename: __filename, category: 'rental-list' })

const TIMEZONE = 'Asia/Bangkok'
const DATE_FORMAT = 'MMMM Do YYYY, h:mm:ss a'

export const RentalList: FC = () => {
  const [checkingIds, setCheckingId] = useState<CheckingId[]>([])
  const [checkedIds, setCheckedIds] = useState<number[]>([])
  const currentPage = useMemo(() => {
    const params = new URLSearchParams(window.location.search)
    const parsedPage = params.get('page') ? parseInt(params.get('page') ?? '', 10) : 1

    return parsedPage
  }, [window.location.search])
  const provinces = useMemo(() => {
    const params = new URLSearchParams(window.location.search)

    return params.get('provinces') ?? ''
  }, [window.location.search])

  const listingSources = useMemo(() => {
    const params = new URLSearchParams(window.location.search)

    return params.get('listingSources') ?? ''
  }, [window.location.search])

  const { data, isLoading } = useRentalList(currentPage, provinces, listingSources)
  const { data: totalData, isLoading: paginationLoading } = useRentalListCount(provinces, listingSources)
  console.log(data?.data.rentals)

  const rentalList = useMemo(
    () =>
      data?.data.rentals
        ?.filter((rental) => !(checkingIds.find((cid) => cid.id === rental.id) && checkedIds.includes(rental.id)))
        .map((rental) => ({
          ...rental,
          extsourceCreatedAt: rental.extsourceCreatedAt
            ? moment(rental.extsourceCreatedAt).tz(TIMEZONE).format(DATE_FORMAT)
            : undefined,
          extsourceUpdatedAt: rental.extsourceUpdatedAt
            ? moment(rental.extsourceUpdatedAt).tz(TIMEZONE).format(DATE_FORMAT)
            : undefined,
        })),
    [checkingIds, data, checkedIds],
  )

  useEffect(() => {
    const handler = (data: any) => {
      const rentalId = toNumber(data.rentalId)
      setCheckedIds((checkedIds) => [...checkedIds, rentalId])
    }
    socket.on('doneCheckingRental', handler)

    return () => {
      socket.off('doneCheckingRental', handler)
    }
  }, [setCheckedIds])

  useEffect(() => {
    if (!!socket.connected) {
      socket.emit('requestCheckingList')
    }
    const handler = () => {
      logger.info('start connect')
      socket.emit('requestCheckingList')
    }
    socket.on('connect', handler)

    return () => {
      socket.off('connect', handler)
    }
  }, [])

  useEffect(() => {
    const handler = async () => {
      const {
        data: { checkingIds },
      } = await api.getCheckingIds()
      logger.info('api.getCheckingIds()', checkingIds as unknown as LoggerDetails)
      setCheckingId(checkingIds)
    }
    socket.on('getCheckingList', handler)

    return () => {
      socket.off('getCheckingList', handler)
    }
  }, [setCheckingId])

  return (
    <>
      <div className="flex gap-4 p-4 pb-0 mt-2">
        <ListingSourceFilter />
        <ProvinceFilter />
      </div>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <RentalListTable
          paginationLoading={paginationLoading}
          rentals={rentalList ?? []}
          currentPage={currentPage}
          totalData={totalData?.data.count}
          checkingIds={checkingIds}
        />
      )}
    </>
  )
}
