import clsx from 'clsx'
import React, { FC, ReactNode } from 'react'

interface IInputRowProps {
  className?: String
  children: ReactNode
}
const InputRow: FC<IInputRowProps> = ({ children, className }) => {
  const inputRowClassnames = clsx('flex gap-2 items-center', className)

  return <div className={inputRowClassnames}>{children}</div>
}

export default InputRow
