import { AbstractUnitType } from '@lib/enum'
import { NumberOfBed, UnitTypes } from '@property-scout/shared-enum'

export const bedRoomsToAbstractUnitTypeMap: Partial<Record<NumberOfBed, AbstractUnitType>> = {
  one_bedroom: AbstractUnitType.one_bedroom,
  two_bedrooms: AbstractUnitType.two_bedrooms,
  three_bedrooms: AbstractUnitType.three_bedrooms,
  four_bedrooms: AbstractUnitType.four_bedrooms,
  five_bedrooms: AbstractUnitType.five_bedrooms,
  six_bedrooms: AbstractUnitType.six_bedrooms,
  seven_bedrooms: AbstractUnitType.seven_bedrooms,
  eight_bedrooms: AbstractUnitType.eight_bedrooms,
  nine_bedrooms: AbstractUnitType.nine_bedrooms,
  ten_bedrooms: AbstractUnitType.ten_bedrooms,
}

export const abstractToUnitTypeMap: Record<AbstractUnitType, { unitType: UnitTypes; numberBedrooms: NumberOfBed }> = {
  studio: {
    unitType: UnitTypes.Studio,
    numberBedrooms: NumberOfBed.OneBed,
  },
  '1br': {
    unitType: UnitTypes.One,
    numberBedrooms: NumberOfBed.OneBed,
  },
  '2br': {
    unitType: UnitTypes.Two,
    numberBedrooms: NumberOfBed.TwoBed,
  },
  '3br': {
    unitType: UnitTypes.Three,
    numberBedrooms: NumberOfBed.ThreeBed,
  },
  '4br': {
    unitType: UnitTypes.FourPlus,
    numberBedrooms: NumberOfBed.FourBed,
  },
  '5br': {
    unitType: UnitTypes.FourPlus,
    numberBedrooms: NumberOfBed.FiveBed,
  },
  '6br': {
    unitType: UnitTypes.FourPlus,
    numberBedrooms: NumberOfBed.SixBed,
  },
  '7br': {
    unitType: UnitTypes.FourPlus,
    numberBedrooms: NumberOfBed.SevenBed,
  },
  '8br': {
    unitType: UnitTypes.FourPlus,
    numberBedrooms: NumberOfBed.EightBed,
  },
  '9br': {
    unitType: UnitTypes.FourPlus,
    numberBedrooms: NumberOfBed.NineBed,
  },
  '10br': {
    unitType: UnitTypes.FourPlus,
    numberBedrooms: NumberOfBed.TenBed,
  },
}
