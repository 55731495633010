import { Spin } from '@property-scout/core-ui'
import { ResponseDialog, ResponseDialogStatus } from '@property-scout/core-ui/lib/Modal/Dialog'
import { IImageResolution, validateImageResolution } from '@property-scout/core-ui/lib/utils/imageResolution'
import { Svg } from '@property-scout/icons'
import React, { FC, useCallback, useState } from 'react'
import { DropzoneProps, useDropzone } from 'react-dropzone'

const getImageResolutionWarning = ({ width, height }: IImageResolution): string =>
  `Image resolution can not be more than: ${width}x${height}`

const classes = {
  wrapper: (borderColor: string, isDragActive: boolean): string =>
    `text-center p-10 cursor-pointer rounded-lg border-4 relative border-dashed ${borderColor} ${
      isDragActive ? 'bg-gray-200' : 'bg-transparent'
    } hover:bg-gray-200 transition-colors duration-200 ease-in`,
  icon: `
      w-7 h-7 mx-auto mb-4
    `,
}

export interface IDropzoneProps extends DropzoneProps {
  onFileAccepted: (acceptedFiles: File[]) => void
  allowedImageResolution?: IImageResolution
}

const Dropzone: FC<IDropzoneProps> = ({
  onFileAccepted,
  accept,
  maxFiles,
  multiple,
  maxSize,
  allowedImageResolution,
}) => {
  const [resolutionError, setResolutionError] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)

  const onDrop = useCallback(
    async (acceptedFiles) => {
      try {
        setLoading(true)

        if (!allowedImageResolution) {
          onFileAccepted(acceptedFiles)

          return
        }

        let hasResolutionErrors = false
        const validFiles: File[] = []

        for (const file of acceptedFiles) {
          const isValidResolution = await validateImageResolution(file, allowedImageResolution)

          if (!isValidResolution) {
            hasResolutionErrors = true
          } else {
            validFiles.push(file)
          }
        }

        if (validFiles.length) {
          onFileAccepted(validFiles)
        }

        if (hasResolutionErrors) {
          setResolutionError(getImageResolutionWarning(allowedImageResolution))
        }
      } catch (error) {
        console.log(error)
      }

      setLoading(false)
    },
    [onFileAccepted, allowedImageResolution],
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    maxFiles,
    multiple,
    maxSize,
  })

  const dropText = isDragActive ? 'Drop the files here ...' : "Drag 'n' drop your files here, or click to select files"

  const borderColor = isDragActive ? 'border-teal-300' : 'border-teal-600'

  const handleCloseDialog = (): void => {
    setResolutionError(null)
  }

  return (
    <>
      <div className={classes.wrapper(borderColor, isDragActive)} {...getRootProps()}>
        <input {...getInputProps()} data-testid="file-upload" />
        {loading ? <Spin className={classes.icon} /> : <Svg src="detail-image-1" className={classes.icon} />}
        <p>{dropText}</p>
      </div>
      <ResponseDialog
        isOpen={!!resolutionError}
        onClose={handleCloseDialog}
        title="Error"
        status={ResponseDialogStatus.Error}
      >
        {resolutionError}
      </ResponseDialog>
    </>
  )
}

export default Dropzone
