import InputValidateIcon from '@components/InputValidationIcon'
import { POSTED_BY_OPTIONS } from '@lib/constants'
import { useRentalInfoContext } from '@lib/context/RentalInfoContext'
import { RadioGroup } from '@property-scout/core-ui/lib/Forms/RadioGroup'
import { InputActiveColor } from '@property-scout/core-ui/lib/Forms/utils'
import clsx from 'clsx'
import React, { FC, useMemo } from 'react'

interface IPostedByProps {
  valid?: boolean
}

const PostedBy: FC<IPostedByProps> = ({ valid }) => {
  const { form: rental, setField } = useRentalInfoContext()
  const invalid = useMemo(() => (valid === undefined ? false : !valid), [valid])
  const labelClassName = clsx('mr-2 font-semibold shrink-0', invalid && 'text-red-500')
  const disabledRadioItems = POSTED_BY_OPTIONS.filter((item) => !rental.availPostBy?.includes(item.id)).map(
    (item) => item.id,
  )

  return (
    <div className="flex items-center">
      <div className={labelClassName} data-testid="postBy-label">
        Posted By
        <InputValidateIcon valid={valid} />
      </div>
      <RadioGroup
        activeColor={InputActiveColor.orangeSolid}
        onChange={rental.disableChangePostBy ? () => {} : setField('postBy')}
        value={rental.postBy ?? ''}
        className="inline-flex !mb-0 flex-wrap"
        itemClassName="!p-1 !px-2 !mr-1 !mb-0"
        labelClassName="inline-flex mr-2"
        options={POSTED_BY_OPTIONS}
        variant={1}
        disabledItem={disabledRadioItems}
      />
    </div>
  )
}

export default PostedBy
