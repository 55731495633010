import clsx from 'clsx'
import React, { FC, ReactNode } from 'react'

interface IFormCardProps {
  className?: string
  children: ReactNode
}

const FormCard: FC<IFormCardProps> = ({ children, className }) => {
  const formCardClassnames = clsx('border rounded-lg border-gray-200 p-4 mb-4', className)

  return <div className={formCardClassnames}>{children}</div>
}

export default FormCard
