import InputValidateIcon from '@components/InputValidationIcon'
import { useRentalInfoContext } from '@lib/context/RentalInfoContext'
import * as rentalForm from '@lib/modules/rental/form'
import { InputField as Input } from '@property-scout/core-ui/lib/Forms/InputField'
import clsx from 'clsx'
import React, { FC, useMemo } from 'react'

interface IPriceProps {
  valid?: boolean
}

const Price: FC<IPriceProps> = ({ valid }) => {
  const { form: rental, setField } = useRentalInfoContext()

  const invalid = useMemo(() => (valid === undefined ? false : !valid), [valid])
  const inputClassName = 'grow-0 shrink-0 basis-[48%] !mb-0 max-w-[48%] sm:max-w-[23%] xl:w-[110px]'

  return (
    <div className="flex items-center gap-2 mb-5 flex-wrap xl:flex-nowrap" id="price">
      <div className={clsx('shrink-0', invalid && 'text-red-500')} data-testid="rentalPrice-label">
        Rental Price
        <InputValidateIcon valid={valid} />
      </div>
      <div className="flex flex-wrap gap-2">
        <Input
          onChange={(value) => setField('monthlyPriceMin12Months')(rentalForm.normalizeTextNumber(value as string))}
          placeholder="12M"
          testId="inputPriceMin12Months"
          value={rental.monthlyPriceMin12Months || ''}
          className={inputClassName}
        />
        <Input
          onChange={(value) => setField('monthlyPriceMin6Months')(rentalForm.normalizeTextNumber(value as string))}
          placeholder="6M"
          testId="inputPriceMin6Months"
          value={rental.monthlyPriceMin6Months || ''}
          className={inputClassName}
        />
        <Input
          onChange={(value) => setField('monthlyPriceMin3Months')(rentalForm.normalizeTextNumber(value as string))}
          placeholder="3M"
          testId="inputPriceMin3Months"
          value={rental.monthlyPriceMin3Months || ''}
          className={inputClassName}
        />
        <Input
          onChange={(value) => setField('monthlyPriceMin1Month')(rentalForm.normalizeTextNumber(value as string))}
          placeholder="1M"
          testId="inputPriceMin1Month"
          value={rental.monthlyPriceMin1Month || ''}
          className={inputClassName}
        />
      </div>
    </div>
  )
}

export default Price
