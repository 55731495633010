import { FormHook } from '@hooks/useForm'
import { IErrorForm, RentalForm } from '@interfaces/rental'
import { createContext, useContext } from 'react'

export type RentalInfoContextType = {
  setRadioField: (field: string) => (val: any) => void
  openModal: number
  setModal: (value: number) => void
  onClickSubmit: () => void
} & FormHook<RentalForm, IErrorForm>

const RentalInfoContext = createContext<RentalInfoContextType>({} as RentalInfoContextType)
export const RentalInfoProvider = RentalInfoContext.Provider
export const RentalInfoConsumer = RentalInfoContext.Consumer

export const useRentalInfoContext = () => useContext(RentalInfoContext)
export default RentalInfoContext
