import InputValidateIcon from '@components/InputValidationIcon'
import { useRentalInfoContext } from '@lib/context/RentalInfoContext'
import { useStyles } from '@lib/hooks'
import * as rentalForm from '@lib/modules/rental/form'
import { InputField as Input } from '@property-scout/core-ui/lib/Forms/InputField'
import clsx from 'clsx'
import React, { FC, useMemo } from 'react'

interface ISalePriceProps {
  valid?: boolean
}

const SalePrice: FC<ISalePriceProps> = ({ valid }) => {
  const { form: rental, setField } = useRentalInfoContext()
  const styles = useStyles()
  const invalid = useMemo(() => (valid === undefined ? false : !valid), [valid])

  return (
    <div className="flex items-center gap-2" id="salePrice">
      <div className={clsx(invalid && 'text-red-500')} data-testid="salePrice-label">
        Sale Price
        <InputValidateIcon valid={valid} />
      </div>
      <Input
        onChange={(value) => setField('salePrice')(rentalForm.normalizeTextNumber(value as string))}
        placeholder="Sale price"
        value={rental.salePrice || ''}
        className="w-[110px] !mb-0"
        inputClassName={styles.inputStyle(!invalid)}
      />
    </div>
  )
}

export default SalePrice
