import { IUploadedImage } from '@interfaces/images'
import { ACCEPTED_UPLOAD_IMAGE_FORMAT, ACCEPTED_UPLOAD_IMAGE_SIZE, ALLOWED_IMAGE_RESOLUTION } from '@lib/constants'
import { CDN_URL, MAX_FILES_UPLOAD_COUNTS } from '@lib/constants'
import { useRentalInfoContext } from '@lib/context/RentalInfoContext'
import { getValue } from '@lib/hooks'
import { OutlineButton } from '@property-scout/core-ui/lib/Buttons/OutlineButton'
import { ImageCheckingForm } from '@property-scout/core-ui/lib/ImageCheckingForm'
import { ISetField } from '@property-scout/core-ui/lib/ImageCheckingForm/types'
import { Modal } from '@property-scout/core-ui/lib/Modal'
import { Svg } from '@property-scout/icons/lib/Svg'
import { FC, useCallback, useState } from 'react'
import React from 'react'

import Dropzone from '../Dropzone'

export const NewImages: FC = () => {
  const { form: rental, setForm, error, setField } = useRentalInfoContext()
  const [modalImage, setModalImage] = useState<string | null>(null)
  const [modalImageAlt, setModalImageAlt] = useState<string | null>('')

  const imageFileMapper = (img: File): IUploadedImage => {
    const image = {
      alt: null,
      attractiveImage: false,
      badImage: false,
      caption: null,
      exterior: '',
      roomType: '',
      id: null,
      url: '',
      imageScore: -1,
      ps_watermark_id: '',
      ps_no_watermark_id: '',
      wl_watermark_id: '',
      wl_no_watermark_id: '',
      status: null,
      isPublished: null,
      tags: {
        bathtub: false,
        bigBalcony: false,
        bigLivingroom: false,
        cornerUnit: false,
        greatView: false,
        kitchenClosedLayout: false,
        kitchenOpenLayout: false,
        kitchenGasStove: false,
        kitchenOven: false,
        walkinCloset: false,
      },
    }

    // we are using the alt as identifier to check if it used as featured  image  and it should be image name
    return { ...image, url: URL.createObjectURL(img), alt: img.name, id: img.size * Math.random() * 10000, file: img }
  }

  const handleFileDrop = (files: File[]) => {
    const images = rental.uploadedImages
    let newImages = files.map(imageFileMapper).filter((img) => !images.some((i) => i.id === img.id))
    newImages = [...images, ...newImages]
    setForm((current) => ({ ...current, uploadedImages: newImages }))
  }

  const setFieldInArray =
    (itemIndex: number): ISetField =>
    (formKey, formItemKey, setFormItem) =>
    (e) => {
      setForm((current) => ({
        ...current,
        [formKey]: current[formKey as keyof typeof current].map((item: any, index: number) => {
          if (itemIndex === index) {
            const value = getValue(e)

            return {
              ...item,
              [formItemKey]: setFormItem ? setFormItem(item[formItemKey], value) : value,
            }
          }

          return item
        }),
      }))
    }

  const removeImage = (index: number) => {
    const newImages = [...rental.uploadedImages]
    if (rental.featuredImage && rental.featuredImage.url === newImages[index]?.url) {
      setForm((current) => ({ ...current, featuredImage: null }))
    }
    newImages.splice(index, 1)
    setForm((current) => ({ ...current, uploadedImages: newImages }))
  }

  const setUnpublishImage = useCallback(
    (image) => {
      setForm((current) => ({
        ...current,
        unpublishedImages: [image, ...current.unpublishedImages],
      }))
    },
    [setForm],
  )

  const unsetUnpublishImage = useCallback(
    (image) => {
      setForm((current) => ({
        ...current,
        unpublishedImages: current.unpublishedImages.filter((img) => img.id !== image.id),
      }))
    },
    [setForm],
  )

  return (
    <div>
      <h2 className="font-bold my-4">New Image Checking</h2>
      <Dropzone
        maxFiles={MAX_FILES_UPLOAD_COUNTS}
        onFileAccepted={handleFileDrop}
        multiple
        maxSize={ACCEPTED_UPLOAD_IMAGE_SIZE}
        accept={ACCEPTED_UPLOAD_IMAGE_FORMAT}
        allowedImageResolution={ALLOWED_IMAGE_RESOLUTION}
      />

      {(rental.uploadedImages || []).map((image, index) => (
        <div className="relative pt-[10px]" key={image.url}>
          <OutlineButton
            onClick={() => removeImage(index)}
            className=" right-[10px] top-[10px] border-transparent hover:bg-slate-600 hover:bg-opacity-20 !p-0 w-auto h-auto ml-auto mr-0 mb-2 mt-4"
          >
            <Svg src="close" className="w-[24px] h-[24px]" />
          </OutlineButton>
          <ImageCheckingForm
            formKey="uploadedImages"
            image={image}
            index={index}
            cdnUrl={CDN_URL!}
            setField={setFieldInArray(index)}
            setFeaturedImage={setField('featuredImage')}
            featuredImage={rental.featuredImage}
            setModalImage={(src) => {
              setModalImage(src) // todo: need to update type in core-ui
              setModalImageAlt(image.alt)
            }}
            valid={error.uploadedImages ? { picType: !!error.uploadedImages[index]?.picType } || {} : {}}
            setUnpublishImage={setUnpublishImage}
            unsetUnpublishImage={unsetUnpublishImage}
          />
        </div>
      ))}

      <Modal isOpen={!!modalImage} onClose={() => setModalImage(null)} width="40rem">
        <img src={`${modalImage}`} className="w-full object-cover" alt={modalImageAlt ?? ''} />
      </Modal>
    </div>
  )
}
