import clsx from 'clsx'
import React from 'react'
import { FC, ReactNode } from 'react'

interface IBadgeProps {
  className?: String
  colorScheme?: 'green' | 'red' | 'gray'
  children: ReactNode
}

const Badge: FC<IBadgeProps> = ({ className, children, colorScheme = 'gray' }) => {
  const badgeClassnames = clsx(
    'inline-flex items-center px-1 py-0.5 rounded text-xs font-bold',
    {
      'bg-green-100 text-green-800': colorScheme === 'green',
      'bg-red-100 text-red-800': colorScheme === 'red',
      'bg-gray-100 text-gray-800': colorScheme === 'gray',
    },
    className,
  )

  return <span className={badgeClassnames}>{children}</span>
}

export default Badge
