import { getLogger } from '@property-scout/common-logger'
import { io } from 'socket.io-client'
const logger = getLogger({ filename: __filename, category: 'socket' })

logger.info('init websocket')

const socket = io(process.env.REACT_APP_API_URL!, {
  ...(process.env.REACT_APP_SOCKETIO_NAMESPACE && {
    path: process.env.REACT_APP_SOCKETIO_NAMESPACE,
  }),
  transports: ['websocket', 'polling'],
})

export default socket
