import { IImageCheckingFormProps } from '@property-scout/core-ui/lib/ImageCheckingForm/types'

export interface Tags {
  name: string
}

export interface CdnImage {
  id: number
  url: string
  caption: string | null
  alt: string | null
  imageScore: number
  roomType?: string
  exterior?: string
  ps_watermark_id: string
  ps_no_watermark_id: string
  wl_watermark_id: string
  wl_no_watermark_id: string
  status?: ImageStatus
  isPublished: boolean
  tags: Tags[]
}

export enum ImageStatus {
  Featured = 'featured',
  Original = 'original',
  Uploaded = 'uploaded',
}

export type IImage = IImageCheckingFormProps['image']
export type IUploadedImage = IImage & { file?: File; id?: number }
