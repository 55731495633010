import InputValidateIcon from '@components/InputValidationIcon'
import { RENTED_OUT_OPTIONS, RENTED_OUT_RENTSELL_OPTIONS } from '@lib/constants'
import { useRentalInfoContext } from '@lib/context/RentalInfoContext'
import { RadioGroup, RadioVariant } from '@property-scout/core-ui/lib/Forms/RadioGroup'
import { InputActiveColor } from '@property-scout/core-ui/lib/Forms/utils'
import { Tenure } from '@property-scout/shared-enum'
import clsx from 'clsx'
import React, { FC, useEffect, useMemo, useState } from 'react'

interface IRentedOutProps {
  valid?: boolean
  isRentSell: boolean
}
const RentedOut: FC<IRentedOutProps> = ({ isRentSell, valid }) => {
  const { form: rental, setField } = useRentalInfoContext()
  const invalid = useMemo(() => (valid === undefined ? false : !valid), [valid])

  const labelClassName = clsx('font-semibold mr-2 shrink-0', invalid && 'text-red-500')
  // Store the previous tenure value
  const [prevTenure, setPrevTenure] = useState(rental.tenure)

  // Check if the value is one of the expected ones
  const checkValue = (value: Tenure | null) => ['rent', 'sell', 'other'].includes(value ?? '')

  useEffect(() => {
    // Check if the previous or current value of tenure requires a reset of rentedOut
    if (checkValue(rental.tenure) || checkValue(prevTenure)) {
      setField('rentedOut')('')
    }

    // Update the previous tenure value
    setPrevTenure(rental.tenure)
  }, [rental.tenure, setField, prevTenure]) // Trigger

  return (
    <div className="flex items-center">
      <div className={labelClassName} data-testid="rentedOut-label">
        Available
        <InputValidateIcon valid={valid} />
      </div>
      <RadioGroup
        activeColor={InputActiveColor.orangeSolid}
        onChange={setField('rentedOut')}
        value={rental.rentedOut ?? ''}
        className="inline-flex !mb-0 flex-wrap gap-y-2"
        itemClassName="!p-1 !px-2 !mr-1 !mb-0"
        labelClassName="inline-flex mr-2"
        options={isRentSell ? RENTED_OUT_RENTSELL_OPTIONS : RENTED_OUT_OPTIONS}
        variant={RadioVariant.buttons}
      />
    </div>
  )
}

export default RentedOut
