import { Navbar } from '@components/Navbar/Navbar'
import { SessionExpire } from '@components/SessionExpire/SessionExpire'
import * as G from '@lib/constants'
import { setUserInfo } from '@lib/userinfo'
import { verifyJWT } from '@pages/Login/login.helper'
import { Spin } from '@property-scout/core-ui/lib/Spin'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useIdleTimerContext } from 'react-idle-timer'
import { Navigate, useNavigate } from 'react-router-dom'

export interface IPrivateRouteProps {
  component: FC
  [key: string]: any
}
const PrivateRoute: FC<IPrivateRouteProps> = ({ component: Component, ...rest }) => {
  const [auth, setAuth] = useState(false)
  const [waiting, setWaiting] = useState(true)
  const [waitingVerifyJWT, setWaitingVerifyJWT] = useState(false)
  const [sessionExpired, setSessionExpired] = useState(false)
  const navigate = useNavigate()
  const idleCtx = useIdleTimerContext()

  const onIdle = useCallback(() => {
    setSessionExpired(true)
  }, [])

  useEffect(() => {
    ;(idleCtx as any).setOnIdle(onIdle)
  }, [idleCtx, onIdle])

  const handleSessionExpire = useCallback(() => {
    setUserInfo()
    localStorage.removeItem(G.TOKEN_KEY)
    setSessionExpired(false)
    navigate('/login')
  }, [navigate])

  const RentalListTableVerifyJWT = async () => {
    const confirmed = await verifyJWT()
    setAuth(confirmed)
    setWaitingVerifyJWT(false)
  }

  useEffect(() => {
    const jwt = localStorage.getItem(G.TOKEN_KEY)
    if (jwt) {
      setWaitingVerifyJWT(true)
      RentalListTableVerifyJWT()
    }
  }, [])

  useEffect(() => {
    const timer = setTimeout(() => setWaiting(false), 400)

    return () => clearTimeout(timer)
  }, [])

  if (waiting || waitingVerifyJWT) {
    return (
      <div className="min-w-full h-screen flex justify-center items-center flex-col">
        <p className="mb-3">Authenticating...</p>
        <Spin />
      </div>
    )
  }

  if (auth) {
    return (
      <>
        <Navbar />
        <div className="px-4 w-full overflow-auto" style={{ height: 'calc(100% - 76px)' }}>
          <Component {...rest} />
          {sessionExpired && <SessionExpire onSessionExpire={handleSessionExpire} isOpen={sessionExpired} />}
        </div>
      </>
    )
  }

  return <Navigate to="/login" replace />
}

export default PrivateRoute
