import InputValidateIcon from '@components/InputValidationIcon'
import { FIRST_LINE_RELEVANCE_OPTIONS, SECOND_LINE_RELEVANCE_OPTIONS } from '@lib/constants'
import { useRentalInfoContext } from '@lib/context/RentalInfoContext'
import { RadioGroup, RadioVariant } from '@property-scout/core-ui/lib/Forms/RadioGroup'
import { InputActiveColor } from '@property-scout/core-ui/lib/Forms/utils'
import clsx from 'clsx'
import React, { FC, useMemo } from 'react'

interface IRelevantListingProps {
  valid?: boolean
}

const RelevantListing: FC<IRelevantListingProps> = ({ valid }) => {
  const { form: rental, setField } = useRentalInfoContext()
  const invalid = useMemo(() => (valid === undefined ? false : !valid), [valid])
  const labelClassName = clsx('font-semibold mr-2 shrink-0', invalid && 'text-red-500')

  return (
    <div className="flex items-start">
      <div className={labelClassName} data-testid="relevance-label">
        Relevant listing
        <InputValidateIcon valid={valid} />
      </div>
      <div>
        <RadioGroup
          activeColor={InputActiveColor.orangeSolid}
          onChange={setField('relevance')}
          value={rental.relevance ?? ''}
          className="flex"
          itemClassName="!p-1 !px-2 !mr-1 !mb-0"
          labelClassName="inline-flex mr-2"
          options={FIRST_LINE_RELEVANCE_OPTIONS}
          variant={RadioVariant.buttons}
        />
        <RadioGroup
          activeColor={InputActiveColor.orangeSolid}
          onChange={setField('relevance')}
          value={rental.relevance ?? ''}
          className="flex !mb-0 flex-wrap gap-y-2"
          itemClassName="!p-1 !px-2 text-sm !mr-1 !mb-0"
          labelClassName="inline-flex mr-2"
          options={SECOND_LINE_RELEVANCE_OPTIONS}
          variant={RadioVariant.buttons}
        />
      </div>
    </div>
  )
}

export default RelevantListing
