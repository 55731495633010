import { CDN_URL } from '@lib/constants'
import { useRentalInfoContext } from '@lib/context/RentalInfoContext'
import { getValue } from '@lib/hooks'
import { ImageCheckingForm } from '@property-scout/core-ui/lib/ImageCheckingForm'
import { ISetField } from '@property-scout/core-ui/lib/ImageCheckingForm/types'
import { Modal } from '@property-scout/core-ui/lib/Modal'
import React, { FC, useCallback, useState } from 'react'

const ImageChecking: FC = () => {
  const { form: rental, setForm, error, setField } = useRentalInfoContext()

  const [modalImage, setModalImage] = useState<string | null>(null)
  const [modalImageAlt, setModalImageAlt] = useState<string>('')
  const setFieldInArray =
    (itemIndex: number): ISetField =>
    (formKey, formItemKey, setFormItem) =>
    (e) => {
      setForm((current) => ({
        ...current,
        [formKey]: current[formKey as keyof typeof current].map((item: any, index: number) => {
          if (itemIndex === index) {
            const value = getValue(e)

            return {
              ...item,
              [formItemKey]: setFormItem ? setFormItem(item[formItemKey], value) : value,
            }
          }

          return item
        }),
      }))
    }

  const setUnpublishImage = useCallback(
    (image) => {
      setForm((current) => ({
        ...current,
        unpublishedImages: [image, ...current.unpublishedImages],
      }))
    },
    [setForm],
  )

  const unsetUnpublishImage = useCallback(
    (image) => {
      setForm((current) => ({
        ...current,
        unpublishedImages: current.unpublishedImages.filter((img) => img.id !== image.id),
      }))
    },
    [setForm],
  )

  return (
    <div>
      <h2 className="font-bold my-4">Original Images</h2>
      {(rental.cdnImages || []).map((image, index) => {
        return (
          <ImageCheckingForm
            key={image.url}
            formKey="cdnImages"
            image={image}
            cdnUrl={CDN_URL!}
            index={index}
            setField={setFieldInArray(index)}
            setFeaturedImage={setField('featuredImage')}
            featuredImage={rental.featuredImage}
            setModalImage={(src) => {
              setModalImage(src)
              setModalImageAlt(image.alt ?? '')
            }}
            valid={error.cdnImages ? { picType: !!error.cdnImages[index]?.picType } : {}}
            setUnpublishImage={setUnpublishImage}
            unsetUnpublishImage={unsetUnpublishImage}
          />
        )
      })}
      <Modal isOpen={!!modalImage} onClose={() => setModalImage(null)} maxWidth={560}>
        <img src={modalImage ?? ''} className="w-full object-cover" alt={modalImageAlt} />
      </Modal>
    </div>
  )
}

export default ImageChecking
