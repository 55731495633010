import { PROPERTY_VIEW_OPTIONS } from '@lib/constants'
import { useRentalInfoContext } from '@lib/context/RentalInfoContext'
import { RadioGroup, RadioVariant } from '@property-scout/core-ui/lib/Forms/RadioGroup'
import { InputActiveColor } from '@property-scout/core-ui/lib/Forms/utils'
import React, { FC, useMemo } from 'react'

interface IRentalViewsProps {}

const RentalViews: FC<IRentalViewsProps> = () => {
  const { form: rental, setField } = useRentalInfoContext()

  // filtering out the PARK_VIEW since it doesn't exist in the cms db atm.
  const options = useMemo(() => PROPERTY_VIEW_OPTIONS.filter((item) => item.id !== 'park_view'), [])

  return (
    <RadioGroup
      label="View"
      activeColor={InputActiveColor.orangeSolid}
      onChange={setField('propertyView')}
      value={rental.propertyView ?? ''}
      className="flex !mb-0 flex-wrap"
      itemClassName="!p-1 !px-2 !mr-1"
      labelClassName="!font-bold mb-6"
      options={options}
      variant={RadioVariant.buttons}
    />
  )
}

export default RentalViews
