import BasicInformation from '@components/Checking/BasicInformation'
import ImageChecking from '@components/ImageChecking/ImageChecking'
import { NewImages } from '@components/ImageChecking/NewImages'
import { SliderActionSlide } from '@components/ImageChecking/SliderActionSlide'
import ListingInformation from '@components/ListingInfomation/ListingInformation'
import { useForm } from '@hooks/index'
import { IUploadedImage } from '@interfaces/images'
import { EnrichedRental, IErrorForm, RentalForm } from '@interfaces/rental'
import * as api from '@lib/api'
import { useNextRentalId } from '@lib/api'
import { AI_IMAGE_CHECK, TOKEN_KEY } from '@lib/constants'
import { RentalInfoProvider } from '@lib/context/RentalInfoContext'
import * as rentalForm from '@lib/modules/rental/form'
import * as rentalUpload from '@lib/modules/rental/upload'
import socket from '@lib/socket'
import { getImageUrl } from '@lib/url'
import { getUserInfo, setUserInfo } from '@lib/userinfo'
import { getLogger, LoggerDetails } from '@property-scout/common-logger'
import { ImageFullView, INACTIVE_SLIDE, ListingsCardLoader } from '@property-scout/core-ui'
import { PrimaryButton as Button } from '@property-scout/core-ui/lib/Buttons/PrimaryButton'
import { Bubbles, BubbleType } from '@property-scout/core-ui/lib/Forms/Bubbles'
import { IBubbleItem } from '@property-scout/core-ui/lib/Forms/Bubbles'
import { Svg } from '@property-scout/icons'
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { v4 } from 'uuid'

const logger = getLogger({ filename: __filename, category: 'rental-info' })

export const RentalInfo: FC = () => {
  const [bubbles, setBubbles] = useState<IBubbleItem[]>([])
  const [bubblesDuration, setBubblesDuration] = useState<number | null>(null)
  const [openModal, setModal] = useState(INACTIVE_SLIDE)
  const [loading, setShowLoading] = useState(false)
  const navigate = useNavigate()
  const { rentalId } = useParams()
  const previousRentalId = useRef<string>()
  const [fetchNextId, setFetchNextId] = useState(false)
  const { data: nextId } = useNextRentalId(fetchNextId)

  const formHook = useForm<RentalForm, IErrorForm>(rentalForm.initForm(), {
    formValidation: rentalForm.checkFormError,
  })

  useEffect(() => {
    if (bubblesDuration && bubbles.length > 0) {
      const timer = setTimeout(() => {
        setBubbles([])
        setBubblesDuration(null)
      }, bubblesDuration)

      return () => clearTimeout(timer)
    }
  }, [bubblesDuration, bubbles])

  const { setForm, form, validateForm } = formHook

  const setRadioField = useCallback(
    (fieldName: string) => (val: any) => {
      setForm((currentForm) => {
        return {
          ...currentForm,
          [fieldName]: val === currentForm[fieldName as keyof RentalForm] ? '' : val,
        }
      })
    },
    [setForm],
  )

  const load = useCallback(async () => {
    try {
      if (!rentalId) {
        throw Error('no rental id')
      }
      setShowLoading(true)
      previousRentalId.current = rentalId
      const rental = await api.getRentalById(rentalId)
      const data = rental.data
      if (data.contact?.info) {
        data.contact.info = data.contact?.info.filter((item) => item.enabled)
      }
      logger.info('data =', data as unknown as LoggerDetails)
      const formData = rentalForm.initForm(data)

      setForm(formData)
      socket.emit('joined', {
        userId: v4(),
        rentalId: data.id,
        contactId: data.contact ? data.contact.id : -1,
      })
      setShowLoading(false)
    } catch (error) {
      logger.error('Unable to load rental by id', error as LoggerDetails)
    }
  }, [setForm, rentalId])

  const onClickPrevious = useCallback(
    (previousRentalId) => {
      if (previousRentalId) {
        window.setTimeout(() => navigate(`/rentals/${previousRentalId}`), 400)
      }
    },
    [navigate],
  )

  const goToNextId = useCallback(async () => {
    setFetchNextId(true)
  }, [])

  const uploadImages = useCallback(
    async (uploadedImages: IUploadedImage[], goBackOnFailed: boolean) => {
      try {
        const response = await rentalUpload.uploadImages(uploadedImages)
        const bubblesData = {
          type: BubbleType.success,
          text: `Upload Image Success`,
        }
        setBubbles((bubbles) => [...bubbles, bubblesData])

        setBubblesDuration(3000)

        return response
      } catch (error) {
        if ([401, 403].includes(parseInt((error as any).response.status, 10))) {
          setUserInfo(undefined)
          localStorage.removeItem(TOKEN_KEY)
          navigate(`/login`)
        }

        const bubblesData = {
          type: BubbleType.error,
          text: `Error Checking from id ${rentalId}`,
        }

        setBubbles((bubbles) => [...bubbles, bubblesData])

        if (!!goBackOnFailed) {
          onClickPrevious(rentalId)
        }
      }
    },
    [rentalId, navigate, onClickPrevious],
  )

  const updateManualChecking = useCallback(
    async (enrichedRental: EnrichedRental, goBackOnFailed: boolean) => {
      try {
        await api.updateRental(rentalId!, enrichedRental)
        const bubblesData = {
          type: BubbleType.success,
          text: `Checking Success`,
        }

        setBubbles((bubbles) => [...bubbles, bubblesData])
        setBubblesDuration(3000)
      } catch (error) {
        if ([401, 403].includes(parseInt((error as any).response?.status, 10))) {
          setUserInfo(undefined)
          localStorage.removeItem(TOKEN_KEY)
          navigate(`/login`)
        }

        const bubblesData = {
          type: BubbleType.error,
          text: `Error Checking from id ${rentalId}`,
        }

        setBubbles((bubbles) => [...bubbles, bubblesData])
        if (goBackOnFailed) {
          onClickPrevious(rentalId)
        }
      }
    },
    [rentalId, navigate, onClickPrevious],
  )

  const onClickSubmit = useCallback(async () => {
    setShowLoading(true)
    setModal(INACTIVE_SLIDE)
    window.scrollTo(0, 0)

    const rentalUpdate = {
      ...form,
      cdnImages: (form.cdnImages || []).filter((image) => image.imageType !== 'badImage'),
      uploadedImages: (form.uploadedImages || []).filter((image) => image.imageType !== 'badImage'),
    }
    const enrichedRental = rentalForm.enrichRental(rentalUpdate)
    let uploadedImages: IUploadedImage[] = []
    if (enrichedRental.uploadedImages.length >= 0) {
      uploadedImages = (await uploadImages(enrichedRental.uploadedImages, true)) ?? []
    }
    enrichedRental.uploadedImages = uploadedImages
    enrichedRental.manual_checking_user = getUserInfo()

    if (rentalUpdate.postBy === 'agent' && !!rentalUpdate.contact && !!rentalUpdate.contact.id) {
      await updateManualChecking(enrichedRental, false)
      setShowLoading(false)
      goToNextId()
    } else {
      setShowLoading(false)
      goToNextId()
      await updateManualChecking(enrichedRental, true)
    }
  }, [form, uploadImages, updateManualChecking, goToNextId])

  const handleSubmitWithoutImageChecking = useCallback(async () => {
    setBubbles([])
    const errorForm = rentalForm.checkFormError(form, false)
    const hasError = Object.keys(errorForm).some((key) => errorForm[key as keyof typeof errorForm])
    if (hasError) {
      validateForm()
      logger.error('errorForm', errorForm as unknown as LoggerDetails)
      const bubbleArrays = Object.entries(errorForm)
        .map(([name, value]) => ({
          type: BubbleType.error,
          text: value,
          href: `#${name}`,
        }))
        .filter((item) => !!item.text)
      setBubbles(bubbleArrays)

      return
    }

    setBubbles([])
    setShowLoading(true)
    window.scrollTo(0, 0)

    const enrichedRental = rentalForm.enrichRental(form)

    enrichedRental.manual_checking_user = getUserInfo()
    if (form.postBy === 'agent' && !!form.contact && !!form.contact.id) {
      await updateManualChecking(enrichedRental, false)
      setShowLoading(false)
      goToNextId()
    } else {
      setShowLoading(false)
      goToNextId()
      await updateManualChecking(enrichedRental, true)
    }
  }, [validateForm, updateManualChecking, goToNextId, form])

  const handleSubmitWithImageChecking = useCallback(() => {
    setBubbles([])
    const errorForm = rentalForm.checkFormError(form, !AI_IMAGE_CHECK)

    if (rentalForm.hasInfoError(errorForm)) {
      if (!AI_IMAGE_CHECK) {
        handleSubmitWithoutImageChecking()
      } else {
        const bubbleArrays = Object.entries(errorForm)
          .map(([name, value]) => ({
            type: BubbleType.error,
            text: value,
            href: `#${name}`,
          }))
          .filter((item) => !!item.text)

        setBubbles(bubbleArrays)
      }

      return
    }

    if (rentalForm.hasError(errorForm)) {
      validateForm()
      const cdnImagesErrors = errorForm?.cdnImages
        ? errorForm.cdnImages
            .filter((img) => img.picType)
            .map((_img) => ({
              type: BubbleType.error,
              text: `Please check original image ${_img.index + 1}`,
              href: `#image-checking-cdnImages-${_img.index}`,
            }))
        : []

      const uploadedImagesErrors = errorForm?.uploadedImages
        ? errorForm.uploadedImages
            .filter((img) => img.picType)
            .map((_img) => ({
              type: BubbleType.error,
              text: `Please check new image ${_img.index + 1}`,
              href: `#image-checking-uploadedImages-${_img.index}`,
            }))
        : []

      const featuredImageError = errorForm?.featuredImage && {
        type: BubbleType.error,
        text: errorForm.featuredImage,
        href: '',
      }

      const bubbleArrays = [...cdnImagesErrors, ...uploadedImagesErrors]
      if (featuredImageError) {
        bubbleArrays.push(featuredImageError)
      }
      setBubbles(bubbleArrays)

      return
    }
    setBubbles([])

    const needCheckImage =
      form.relevance === 'relevant' && form.tenure !== 'other' && ['landlord', 'cobroker'].includes(form.postBy ?? '')

    if (!needCheckImage || AI_IMAGE_CHECK) {
      onClickSubmit()

      return
    }

    setModal(0)
  }, [form, handleSubmitWithoutImageChecking, validateForm, onClickSubmit])

  useEffect(() => {
    if (previousRentalId.current && previousRentalId.current === rentalId) {
      return () => {}
    }
    load()
  }, [rentalId, load])

  useEffect(() => {
    return () => {
      socket.emit('successChecking', { rentalId })
    }
  }, [rentalId])

  useEffect(() => {
    setFetchNextId(false)
    if (nextId) {
      navigate(`/rentals/${nextId}`)
    }
  }, [nextId, navigate])

  useEffect(() => {
    window.scrollTo(0, 0)
    const handler = (): void => {
      logger.info('start connect')
      socket.emit('requestCheckingList')
    }
    socket.on('connect', handler)

    return () => {
      socket.off('connect', handler)
    }
  }, [])

  const allImages = useMemo(() => {
    const imagesToCombine = [...form.cdnImages, ...form.uploadedImages]

    return imagesToCombine.filter(
      (image) => image.imageType !== 'badImage' || !form.unpublishedImages.some((img) => img.id === image.id),
    )
  }, [form.cdnImages, form.unpublishedImages, form.uploadedImages])

  const imageFullViewArgs = useMemo(
    () => ({
      activeSlide: openModal,
      images: [
        ...allImages.map((image) => {
          const imageUrl = getImageUrl(image.url)

          return (
            <div key={image.id} className="flex justify-center">
              <img src={`${imageUrl}`} className="object-contain max-w-[100%] max-h-[80vh]" alt={image.alt ?? ''} />
            </div>
          )
        }),
        <SliderActionSlide disableSubmit={loading} setModal={setModal} onClickSubmit={onClickSubmit} />,
      ],
      arrowIcon: <Svg src="right-arrow" className="invert" />,
      onClose: () => setModal(INACTIVE_SLIDE),
      pagination: true,
    }),
    [allImages, openModal, setModal, onClickSubmit, loading],
  )

  return (
    <RentalInfoProvider value={{ ...formHook, setRadioField, openModal, setModal, onClickSubmit }}>
      {!form || loading ? (
        <ListingsCardLoader />
      ) : (
        <>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
            <ListingInformation />
            <BasicInformation disableSubmit={loading} onSubmitWithoutImageChecking={handleSubmitWithoutImageChecking} />
          </div>
          {!AI_IMAGE_CHECK && (
            <>
              <div className="relative">
                <ImageFullView ignoreLastSlide {...imageFullViewArgs} />
              </div>
              <div className="mt-8">
                <NewImages />
              </div>
              <div className="mt-8">
                <ImageChecking />
              </div>
            </>
          )}
          <div className="flex justify-center">
            <Button
              className="max-lg:h-auto max-lg:py-2"
              id="btn_submit"
              onClick={handleSubmitWithImageChecking}
              disabled={loading}
              isLoading={loading}
            >
              Submit landlord or cobroker listing after complete check
            </Button>
          </div>
          {bubbles.length > 0 && (
            <Bubbles
              onClose={(): void => {
                setBubbles([])
              }}
              items={bubbles.slice(0, 10)}
              className="!fixed"
              testId="bubble-alert"
            />
          )}
        </>
      )}
    </RentalInfoProvider>
  )
}
