import { CheckingId } from '@api/types'
import { ShortRental } from '@interfaces/rental'
import { columns, PAGE_LIMIT } from '@lib/constants'
import RentalTable from '@property-scout/core-ui/lib/RentalTable'
import { Spin } from '@property-scout/core-ui/lib/Spin'
import React, { FC, useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

interface IRentalListProps {
  rentals: ShortRental[]
  checkingIds: CheckingId[]
  currentPage: number
  totalData?: number
  paginationLoading: boolean
}
export const RentalListTable: FC<IRentalListProps> = ({
  totalData,
  currentPage,
  rentals,
  checkingIds,
  paginationLoading,
}) => {
  const navigate = useNavigate()
  const totalPage = useMemo(() => (totalData ? Math.ceil(totalData / PAGE_LIMIT) : undefined), [totalData])
  const handlePageChange = useCallback(
    (page: number): void => {
      const searchParams = new URLSearchParams(window.location.search)
      searchParams.set('page', page.toString())
      const nextPage = `/rental-list?${searchParams.toString()}`
      navigate(nextPage, { replace: true })
    },
    [navigate],
  )

  const validChecking = useCallback((row) => !!checkingIds.find((rt) => rt.id === row.id), [checkingIds])
  const validContactChecking = useCallback(
    (row) => !!checkingIds.find((rt) => row.contact_id === rt.contact_id),
    [checkingIds],
  )

  const onClickRow = useCallback(
    (row) => {
      const isChecking = validChecking(row)
      if (isChecking) {
        return
      }

      navigate(`/rentals/${row.id}`)
    },
    [validChecking, navigate],
  )

  const rowClassnames = (rowData): string => {
    const isChecking = validChecking(rowData)
    const isContactChecking = validContactChecking(rowData)

    if (isChecking) {
      return 'cursor-pointer bg-blue-300 hover:bg-blue-300'
    } else if (isContactChecking) {
      return 'cursor-pointer bg-blue-100 hover:bg-blue-100'
    }

    return 'cursor-pointer bg-inherit hover:bg-blue-50'
  }

  return (
    <div className="p-4">
      <span>Total data {paginationLoading ? <Spin /> : `(${totalData?.toLocaleString('en')})`}</span>
      <div className="w-full h-[calc(100vh-181px)] overflow-auto" id="contentContainer">
        <RentalTable
          data={rentals}
          columns={columns}
          onRowClick={onClickRow}
          onPageChange={handlePageChange}
          getRowClassName={rowClassnames}
          currentPage={currentPage}
          totalPages={totalPage}
          pageSize={PAGE_LIMIT}
          rowKeyField="id"
          tableWrapperClassName="h-full"
        />
      </div>
    </div>
  )
}
