import BuildingName from '@components/Checking/BuildingName'
import FormCard from '@components/FormCard'
import InputRow from '@components/InputRow'
import InputValidateIcon from '@components/InputValidationIcon'
import { ContactInfo } from '@interfaces/contact'
import { AI_IMAGE_CHECK, AMENITIES, BUILDING_FEATURES } from '@lib/constants'
import { useRentalInfoContext } from '@lib/context/RentalInfoContext'
import { useStyles } from '@lib/hooks'
import * as rentalForm from '@lib/modules/rental/form'
import { ContactEditor } from '@property-scout/core-ui'
import { PrimaryButton as Button } from '@property-scout/core-ui/lib/Buttons/PrimaryButton'
import { FeaturesList, IFeaturesList } from '@property-scout/core-ui/lib/FeaturesList'
import { Bubbles } from '@property-scout/core-ui/lib/Forms/Bubbles'
import { IBubbleItem } from '@property-scout/core-ui/lib/Forms/Bubbles'
import { IContactInfoItem } from '@property-scout/core-ui/lib/Forms/ContactEditor/utils/interface'
import { InputField as Input } from '@property-scout/core-ui/lib/Forms/InputField'
import { Tenure } from '@property-scout/shared-enum'
import clsx from 'clsx'
import React, { FC, useMemo, useState } from 'react'

import BathRoom from './BathRooms'
import FloorSize from './FloorSize'
import LandArea from './LandArea'
import LandTitleDeed from './LandTitleDeed'
import PostedBy from './PostedBy'
import Price from './Price'
import PropertyType from './PropertyType'
import RelevantListing from './RelevantListing'
import RentalViews from './RentalViews'
import RentedOut from './RentedOut'
import RentOrSell from './RentOrSell'
import SalePrice from './SalePrice'
import UnitType from './UnitType'

interface IBasicInformationProps {
  disableSubmit: boolean
  onSubmitWithoutImageChecking: () => void
}

const BasicInformation: FC<IBasicInformationProps> = ({ disableSubmit, onSubmitWithoutImageChecking }) => {
  const { form: rental, setField, setForm, error } = useRentalInfoContext()
  const styles = useStyles()
  const { valResult: formErrors, invalidContacts } = useMemo(
    () => rentalForm.formValidation(rental, error),
    [rental, error],
  )
  const [bubbles, setBubbles] = useState<IBubbleItem[]>([])
  const isRentSell = useMemo(() => rental.tenure === Tenure.RentSell, [rental])

  const contacts = rental.contact ? rental.contact.info : []

  const onContactChange = (updatedContact: IContactInfoItem[]): void => {
    setForm((currentForm) => ({
      ...currentForm,
      contact: {
        ...(rental.contact || {}),
        info: updatedContact as ContactInfo[],
      },
    }))
  }

  return (
    <div className="p-2" id="checking-form">
      <h2 className="font-bold mb-6">Checking</h2>
      <FormCard>
        <h3 className="font-bold mb-6">Classify listing</h3>
        <fieldset className="mb-5" id="postBy">
          <PostedBy valid={formErrors.postBy} />
        </fieldset>
        <div className="mb-5" id="tenure">
          <RentOrSell valid={formErrors.tenure} />
        </div>
        <div className="mb-5" id="rentedOut">
          <RentedOut isRentSell={isRentSell} valid={formErrors.rentedOut} />
        </div>
        <div className="mb-5" id="relevance">
          <RelevantListing valid={formErrors.relevance} />
        </div>
      </FormCard>
      <FormCard>
        <h3 className="font-bold mb-6">Project/Building Features</h3>
        <FeaturesList
          setForm={setForm as unknown as IFeaturesList['setForm']}
          form={rental as unknown as IFeaturesList['form']}
          options={BUILDING_FEATURES}
        />
      </FormCard>
      <FormCard>
        <h3 className="font-bold mb-6">Amenities</h3>
        <FeaturesList
          setForm={setForm as unknown as IFeaturesList['setForm']}
          form={rental as unknown as IFeaturesList['form']}
          options={AMENITIES}
        />
      </FormCard>
      <FormCard>
        <RentalViews />
      </FormCard>
      <FormCard>
        <h3 className="font-bold mb-6">Confirm building or lats/longs</h3>
        <div className="mb-5">
          <InputRow className="w-full">
            <BuildingName setBubbles={setBubbles} />
            {formErrors.building !== undefined && (
              <InputValidateIcon data-testid="building-validate-icon" valid={formErrors.building} />
            )}
          </InputRow>
        </div>
        <div className="flex items-center gap-2">
          <span
            data-testid="wizard.building"
            className={clsx(
              (!formErrors.lat || !formErrors.lng) &&
                (formErrors.lng !== undefined || formErrors.lng !== undefined) &&
                'text-red-500',
            )}
          >
            Coordinates
          </span>
          <InputRow>
            <div className="flex items-center" id="lat">
              <div className="mr-2">Lat.</div>
              <Input
                testId="inputLat"
                onChange={setField('lat')}
                value={rental.lat}
                className="w-[100px] !mb-0"
                inputClassName={styles.inputStyle(formErrors.lat)}
              />
              {formErrors.lat !== undefined && <InputValidateIcon valid={formErrors.lat} />}
            </div>
            <div className="flex items-center" id="lng">
              <div className="mr-2">Lng.</div>
              <Input
                testId="inputLng"
                onChange={setField('lng')}
                value={rental.lng}
                className="w-[100px] !mb-0"
                inputClassName={styles.inputStyle(formErrors.lng)}
              />
              {formErrors.lng !== undefined && <InputValidateIcon valid={formErrors.lng} />}
            </div>
          </InputRow>
        </div>
      </FormCard>
      <FormCard>
        <h3 className="font-bold mb-6">Confirm property details</h3>
        <fieldset className="mb-5" id="propertyType">
          <PropertyType valid={formErrors.propertyType} />
        </fieldset>
        <fieldset className="mb-5" id="unitType">
          <UnitType valid={formErrors.abstractUnitType} />
        </fieldset>
        <fieldset className="mb-5" id="bathRooms">
          <BathRoom valid={formErrors.numberBathrooms} />
        </fieldset>
        <div className="mb-5" id="floorSize">
          <FloorSize valid={formErrors.floorSize} />
        </div>
        <div className="mb-5" id="landArea">
          <LandArea valid={formErrors.landArea} />
        </div>
        <div className="mb-5">
          <InputRow>
            <div>Floor level</div>
            <Input
              className="max-w-[70%] !mb-0"
              value={rental.floorLevel || ''}
              onChange={(value): void => setField('floorLevel')(value)}
              placeholder="Floor Level"
            />
          </InputRow>
        </div>
        <Price valid={formErrors.price} />
        <SalePrice valid={formErrors.salePrice} />
        <div className="my-5">
          <InputRow>
            <div>Reference ID</div>
            <Input
              value={rental.propertyId || ''}
              onChange={(value): void => setField('propertyId')(value)}
              placeholder="Reference ID"
              className="max-w-[70%] !mb-0"
            />
          </InputRow>
        </div>
        <fieldset className="mb-5" id="propertyType">
          <LandTitleDeed valid={formErrors.landTitleDeed} />
        </fieldset>
        <div className="mb-5">
          <InputRow>
            <div>CAM Fees</div>
            <Input
              value={rental.camFees || ''}
              onChange={(value): void => {
                setField('camFees')(value === '' ? null : rentalForm.normalizeTextNumber(value as string))
              }}
              placeholder="CAM Fees"
              className="!mb-0"
            />
          </InputRow>
        </div>
      </FormCard>
      <FormCard>
        <h3 className="font-bold mb-6">Confirm contact details</h3>
        <ContactEditor
          bitrixUrlPlaceholder="e.g. https://propertyscout.bitrix24.com/crm/deal/details/XXXXXX/"
          showErrors
          errors={formErrors}
          showBitrixUrl
          value={contacts}
          onChange={onContactChange}
          rawScrape={rental.rawScrape}
          showRequired={false}
          highlight={invalidContacts}
        />
      </FormCard>
      {!AI_IMAGE_CHECK && (
        <div className="flex justify-center">
          <Button disabled={disableSubmit} onClick={onSubmitWithoutImageChecking} className="mb-4">
            Submit non-relevant or external agent listing (no image check)
          </Button>
        </div>
      )}
      {bubbles.length > 0 && (
        <Bubbles
          onClose={(): void => {
            setBubbles([])
          }}
          items={bubbles.slice(0, 10)}
          className="!fixed"
        />
      )}
    </div>
  )
}

export default BasicInformation
