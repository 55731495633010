import { GetRentalsCountResponse } from '@backend/types'
import { GetAreasResponse, NormalizedShortArea } from '@interfaces/areas'
import { PoiBuilding } from '@interfaces/building'
import { EnrichedRental, Rental } from '@interfaces/rental'
import { PAGE_LIMIT } from '@lib/constants'
import { AxiosResponse } from 'axios'
import trimEnd from 'lodash/trimEnd'
import { useQuery, UseQueryResult } from 'react-query'

import { axiosCMSWithAuth, axiosWithAuth } from './instance'
import { CheckingIdResponse, GetRentalResponse, UploadImageResponse } from './types'

export const updateRental = (rentalId: string, data: EnrichedRental) => {
  return axiosWithAuth({
    method: 'PUT',
    url: `/rentals/${rentalId}`,
    data,
  })
}

export const uploadRentalImages = (data: FormData) => {
  return axiosWithAuth<UploadImageResponse[]>({
    method: 'POST',
    url: `/rentals/upload`,
    headers: {
      'content-type': 'multipart/form-data',
    },
    data,
  })
}

export const getRentalById = (rentalId: string) => {
  return axiosCMSWithAuth<Rental>({
    method: 'GET',
    url: `/rentals/getmanuallychecking/${rentalId}`,
  })
}

export const getProvinces = async (keyword: string): Promise<NormalizedShortArea[]> => {
  const { data } = await axiosCMSWithAuth<GetAreasResponse>({
    method: 'GET',
    url: `poi-areas/search?_q=${trimEnd(keyword)}&_limit=20&area_type_short=province`,
  })

  return data.data.map((b) => ({ ...b, name: `${b.name.en} / ${b.name.th}`, original_name: b.name.en }))
}

export const getPoiBuildingFromId = async (id: number) => {
  return axiosCMSWithAuth<PoiBuilding>({
    method: 'GET',
    url: `poi-buildings/${id}`,
  })
}

export const getCheckingIds = () => {
  return axiosWithAuth<CheckingIdResponse>({
    method: 'GET',
    url: `checkingIds`,
  })
}

export const useRentalListCount = (
  provinces: string,
  listingSources: string,
): UseQueryResult<AxiosResponse<GetRentalsCountResponse, any>> => {
  return useQuery(['rental-list-counts', provinces, listingSources], () =>
    axiosWithAuth<GetRentalsCountResponse>({
      method: 'GET',
      url: `/rentals/count?provinces=${provinces}&listingSources=${listingSources}`,
    }),
  )
}

export const useNextRentalId = (fetchNextId: boolean): UseQueryResult<number> => {
  return useQuery(
    ['next-rental-id'],
    () =>
      axiosWithAuth<{ nextId: number }>({
        method: 'GET',
        url: `/nextRentalId`,
      }),
    { enabled: fetchNextId, select: (data) => data.data.nextId },
  )
}

export const useRentalList = (
  page: number,
  provinces: string,
  listingSources: string,
): UseQueryResult<AxiosResponse<GetRentalResponse, any>> => {
  let pageNumber = page
  if (!pageNumber || pageNumber < 1) {
    pageNumber = 1
  }
  const queryParams = {
    limit: PAGE_LIMIT,
    start: (pageNumber - 1) * PAGE_LIMIT,
    provinces,
  }

  return useQuery(['rental-list', pageNumber, provinces, listingSources], () =>
    axiosWithAuth<GetRentalResponse>({
      method: 'GET',
      url: `/rentals?_start=${queryParams.start}&_limit=${queryParams.limit}&provinces=${queryParams.provinces}&listingSources=${listingSources}`,
    }),
  )
}
