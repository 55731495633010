import { getProvinces } from '@api/index'
import type { IVirtualComboboxItem } from '@property-scout/core-ui/lib'
import { RemoteVirtualComboBox } from '@property-scout/core-ui/lib/Forms/RemoteVirtualComboBox'
import { InputActiveColor } from '@property-scout/core-ui/lib/Forms/utils'
import type { FC } from 'react'
import React, { useCallback, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

export const ProvinceFilter: FC = () => {
  const [loading, setLoading] = useState(false)
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const handleChange = useCallback(
    (fieldValue?: IVirtualComboboxItem[] | IVirtualComboboxItem | null): void => {
      const newParams = new URLSearchParams(searchParams)

      newParams.delete('page')
      if (fieldValue && Array.isArray(fieldValue) && fieldValue.length > 0) {
        const provinces = fieldValue.map((item) => item.id).join(', ')
        newParams.set('provinces', provinces)
      } else {
        newParams.delete('provinces')
      }

      navigate(`/rental-list?${newParams.toString()}`, { replace: true })
    },
    [searchParams, navigate],
  )

  const onFetch = useCallback(async (value: string, callback: (newOptions: IVirtualComboboxItem[]) => void) => {
    setLoading(true)
    try {
      const result = await getProvinces(value)
      const parsedItems = result.map((item) => ({
        id: item.original_name,
        content: item.name,
      }))

      callback(parsedItems)
    } catch (e) {
      console.error('Error during loading options', e)
    }

    setLoading(false)
  }, [])

  const selectedProvinces =
    searchParams
      .get('provinces')
      ?.split(',')
      .map((item) => ({ id: item.trim(), content: item.trim() })) ?? []

  return (
    <RemoteVirtualComboBox
      placeholder="Province"
      className="!mb-0 select-none w-[400px]"
      multiSelect
      loading={loading}
      onChange={handleChange}
      fetch={onFetch}
      activeColor={InputActiveColor.orange}
      name="area"
      customValueIcon="search"
      value={selectedProvinces}
    />
  )
}
