const useStyles = () => {
  return {
    inputStyle: (isValid?: boolean, isEmpty?: boolean) => {
      if (isEmpty || isValid === undefined) {
        return ''
      }

      return isValid ? '' : 'border-red-400'
    },
  }
}

export default useStyles
